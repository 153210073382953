import axios from "axios";
import {
  FILTER_CATEGORY_BY_GENDER,
  FILTER_SIZE_BY_CATEGORY,
  RESET_SIDEBAR,
  RESET_ALL_STATE,
  GET_SIDEBAR_REQUEST,
  GET_SIDEBAR_SUCCESS,
  GET_SIDEBAR_ERROR,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERROR,
  GET_FILTER_PRODUCTS_REQUEST,
  GET_FILTER_PRODUCTS_SUCCESS,
  GET_FILTER_PRODUCTS_ERROR,
  INITIAT_DEAL_REQUEST,
  INITIAT_DEAL_SUCCESS,
  INITIAT_DEAL_ERROR,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
} from "../actionTypes";

import { BASE_API_URL } from "../../global/ApiConfig";
// const BASE_API_URL = 'http://80.78.249.52/App/TestPoshApp/API/HiShoppersService.php?Service=';

export const getSidebar = (data, gender) => {
  return (dispatch) => {
    dispatch({ type: GET_SIDEBAR_REQUEST });
    axios
      .post(`${BASE_API_URL}GetProductRelatedDetailsAtOnce`, data)
      .then((res) => {
        dispatch({
          type: GET_SIDEBAR_SUCCESS,
          payload: { data: res.data.data, gender: gender },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SIDEBAR_ERROR,
          payload: err,
        });
      });
  };
};

export const resetSidebar = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SIDEBAR });
  };
};

export const resetAllState = () => (dispatch) => {
  dispatch({ type: RESET_ALL_STATE });
};

export const filterCategoryByGender = (id) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_CATEGORY_BY_GENDER,
      payload: id,
    });
  };
};

// export const filterSizeByCategory = (id) => {
export const filterSizeByCategory = (id, type) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_SIZE_BY_CATEGORY,
      payload: { id: id, type: type },
    });
  };
};

export const getProductList = (data) => {
  return (dispatch) => {
    dispatch({ type: GET_PRODUCTS_REQUEST });
    axios
      .post(`${BASE_API_URL}GetAllProducts`, data)
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PRODUCTS_ERROR,
          payload: err,
        });
      });
  };
};

// export const getFilterProductList = (data) => {
export const getFilterProductList = (data, selectedTags) => {
  return (dispatch) => {
    dispatch({ type: GET_FILTER_PRODUCTS_REQUEST });
    axios
      .post(`${BASE_API_URL}GetProductsWithFilters`, data)
      .then((res) => {
        dispatch({
          type: GET_FILTER_PRODUCTS_SUCCESS,
          // payload: { data: res.data.data, count: res.data.count }
          payload: {
            data: res.data.data,
            count: res.data.count,
            reservData: { filterSelections: data, tagSelections: selectedTags },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FILTER_PRODUCTS_ERROR,
          payload: err,
        });
      });
  };
};

/** Get product details */
export const getProducDetails = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: GET_PRODUCT_DETAILS_REQUEST });
      axios
        .post(`${BASE_API_URL}ShowDetailProduct`, data)
        .then((res) => {
          console.log("getProducDetails ", res);
          dispatch({
            type: GET_PRODUCT_DETAILS_SUCCESS,
            payload: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: GET_PRODUCT_DETAILS_ERROR,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

/** Start Payment */
/*
export const startPayment = (data) => {
    return (dispatch) => {
        dispatch({ type: PAYMENT_REQUEST });
        axios.post('http://80.78.249.52/App/TestPoshApp/API/', data).then(res => {
            console.log('Payment response =>', res)
            dispatch({
                type: PAYMENT_SUCCESS,
                payload: res //.data.data
            })
        }).catch(err => {
            console.log('Payment error=>', err.response)
            dispatch({
                type: PAYMENT_ERROR,
                payload: err
            })
        })
    }
}
*/

/** Initiat the deal */
export const initiateTheDeal = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: INITIAT_DEAL_REQUEST });
      axios
        .post(`${BASE_API_URL}A_InitiateDeal`, data)
        .then((res) => {
          dispatch({
            type: INITIAT_DEAL_SUCCESS,
            payload: res, //.data.data
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: INITIAT_DEAL_ERROR,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

/** Initiate Deal from Buy click */
export const initiateTheDealBuy = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_InitiateDeal`, data)
        .then((res) => {
          console.log("INITIAT_DEAL response 2 =>", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("INITIAT_DEAL error 2 =>", err.response);
          reject(err);
        });
    });
  };
};

/** Confirm Deal from Buy click */
export const confirmDeal = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_ConfirmDeal`, data)
        .then((res) => {
          // console.log("обновили статус сделки с 2 на 3 =>", res);
          // localStorage.removeItem("mp_deal_id_success");
          resolve(res);
        })
        .catch((err) => {
          console.log("confirm deal error =>", err.response);
          reject(err);
        });
    });
  };
};

/** Get link for payment from Buy click */
export const getPaymentLink = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_GetLinkForPayment`, data)
        .then((res) => {
          console.log("Payment link =>", res.data, "dealId =>", data.deal_id);
          // payload: { data: res.data.data, dealId: data.deal_id },
          const resp = { data: res.data.data, dealId: data.deal_id };
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
