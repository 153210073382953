import React, { FC, ReactElement, useState } from "react";
import classNames from "classnames";

import "./checkout-steps.scss";
import { BaseButton } from "..";
import { IBaseInputChange } from "../../models/base-input";
import { BaseInputText } from "../form-fields";

export enum ICheckoutStepsNameType {
  PERSONAL = "personal",
  DELIVERY = "delivery",
  PAYMENT = "payment",
}

enum ICheckoutStepsActionType {
  NEXT_STEP = "next-step",
  TO_STEP = "to-step",
}

export enum ICheckoutStepsStatus {
  PASSED = "passed",
  ACTIVE = "active",
  AWAIT = "await",
}

export interface ICheckoutStepsField {
  name: string;
  label: string;
  value: string;
}

export interface ICheckoutStepsItem {
  name: ICheckoutStepsNameType;
  title: string;
  description: string;
  // component: ReactElement;
  status: ICheckoutStepsStatus;
  buttonText: string;
  withInfoIcon?: boolean;
  note?: string;
  fields?: ICheckoutStepsField[];
}

export interface ICheckoutSteps {
  items: ICheckoutStepsItem[];
  onChange?: (item: ICheckoutStepsItem[] | any) => void;
  onChangeFiels: (name: any, val: any) => void;
}

export const CheckoutSteps: FC<ICheckoutSteps> = ({
  items,
  onChange,
  onChangeFiels,
}): ReactElement => {
  const onClickHandler = (index: number, action: ICheckoutStepsActionType) => {
    let currentStep = items.findIndex(
      (item: ICheckoutStepsItem) => item.status === ICheckoutStepsStatus.ACTIVE
    );

    if (action === ICheckoutStepsActionType.NEXT_STEP) {
      currentStep = currentStep + 1;
    }

    if (action === ICheckoutStepsActionType.TO_STEP) {
      currentStep = index;
    }

    let result = items.map((item: any, index: number) => {
      const status = () => {
        if (index < currentStep) {
          return ICheckoutStepsStatus.PASSED;
        }
        if (index === currentStep) {
          return ICheckoutStepsStatus.ACTIVE;
        }
        if (index > currentStep) {
          return ICheckoutStepsStatus.AWAIT;
        }
      };

      return {
        ...item,
        status: status(),
      };
    });
    if (!!result) {
      onChange && onChange(result);
    }
  };

  const onChangeHandlerInput = (
    name: ICheckoutStepsNameType,
    val: IBaseInputChange
  ): void => {
    // console.log("InputText -> ", val);
    // setText(val);
    onChangeFiels && onChangeFiels(name, val);
  };

  return (
    <ul className='checkout-steps'>
      {items.map((item: ICheckoutStepsItem, index: number) => (
        <li key={index} className='checkout-steps__step'>
          <div
            className={`checkout-steps__head checkout-steps__head--${item.status}`}
          >
            <div
              className={`checkout-steps__head-number checkout-steps__head-number--${item.status}`}
            >
              {index + 1}
            </div>
            <div className='checkout-steps__head-col'>
              <div className='checkout-steps__head-title'>{item.title}</div>
              <div className='checkout-steps__head-description'>
                {item.description}
              </div>
            </div>
          </div>
          <div
            className={`checkout-steps__body checkout-steps__body--${item.status}`}
          >
            {item.status === ICheckoutStepsStatus.ACTIVE && (
              <>
                <ul>
                  {item?.fields &&
                    item?.fields.map((field: any) => (
                      <li>
                        <BaseInputText
                          value={""}
                          placeholder={"Введите ваш никнейм"}
                          header={"Никнейм*"}
                          footer={"Будем использовать для авторизации на сайте"}
                          onChange={() =>
                            onChangeHandlerInput(item.name, field)
                          }
                          error={false}
                        />
                        {/* <div>{field.label}</div>
                        <div>{field.value}</div> */}
                      </li>
                    ))}
                </ul>
                <h4
                  onClick={() =>
                    onClickHandler(index, ICheckoutStepsActionType.TO_STEP)
                  }
                >
                  Редактировать
                </h4>
              </>
            )}

            {/* {item.status === ICheckoutStepsStatus.PASSED && (
              <h4
                onClick={() =>
                  onClickHandler(index, ICheckoutStepsActionType.TO_STEP)
                }
              >
                Редактировать
              </h4>
            )} */}

            {item.status === ICheckoutStepsStatus.ACTIVE && (
              <BaseButton
                onClick={() =>
                  onClickHandler(index, ICheckoutStepsActionType.NEXT_STEP)
                }
              >
                {item.buttonText}
              </BaseButton>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
