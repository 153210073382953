import React, { FC, ReactElement, useState } from "react";
import classNames from "classnames";

import "./progress-numerical.scss";

interface Props {
  activeItem: number;
}

export const ProgressNumerical: FC<Props> = ({ activeItem }): ReactElement => {
  return (
    <div className="progress-numerical">
      <div
        className={classNames(
          "progress-numerical__step progress-numerical__step--one",
          {
            "progress-numerical__step--active": activeItem === 1,
            "progress-numerical__step--checked": activeItem > 1,
          }
        )}
      >
        Авторизация
      </div>
      <div className="progress-numerical__delimiter"></div>
      <div
        className={classNames(
          "progress-numerical__step progress-numerical__step--two",
          {
            "progress-numerical__step--active": activeItem === 2,
          }
        )}
      >
        Запросить личные
      </div>
    </div>
  );
};
