import axios from "axios";
import {
  CREATE_DEAL_REQUEST,
  CREATE_DEAL_SUCCESS,
  CREATE_DEAL_ERROR,
  GET_DEAL_REQUEST,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  ORDER_PAYMENT_REQUEST,
  ORDER_PAYMENT_SUCCESS,
  ORDER_PAYMENT_ERROR,
  SET_SELECT_DEAL,
  CLEAR_SELECT_DEAL,
  GET_DEAL_DETAILS,
  CLEAR_DEAL_DETAILS,
} from "../actionTypes";

import { BASE_API_URL, NEW_API_URL } from "../../global/ApiConfig";
import { setHttpConfig } from "../../shared/helpers/setHttpConfig";

/** Create deal (old api) */
export const createDeal = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: CREATE_DEAL_REQUEST });
      axios
        .post(
          `${BASE_API_URL}A_InitiateDeal`,
          {
            product_id: data.product_id,
            user_id: data.user_id,
            is_payed_auth: data.is_payed_auth,
            // is_web_request: "1",
          },
          setHttpConfig(localStorage.getItem("_token"))
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

/** Get My Deals */
export const getAllDeals = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: GET_DEAL_REQUEST });
    axios
      .post(`${BASE_API_URL}A_GetIBuyProducts`, data)
      // .post(`${BASE_API_URL}A_GetIBuyProducts`, data)
      .then((res) => {
        console.log("getAllDeals >>> ", res);
        dispatch({
          type: GET_DEAL_SUCCESS,
          payload: {
            activeDeals: res.data.data.reverse(),
            cancelledDeals: res.data.closed_cancelled_deals
              ? res.data.closed_cancelled_deals.reverse()
              : [],
            count: res.data.count,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_DEAL_ERROR,
          payload: err,
        });
      });
  };
};

export const getCurrentDeal = ({ data, callback }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_GetDealDetails`, data)
        .then((res) => {
          callback(res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const getDealDetails = (data, callback) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({
        type: GET_DEAL_DETAILS,
        payload: success.data.data,
      });
      console.log("getDealDetails", success);
      callback(success);
      return success;
    }
    function onError(error) {
      // console.log(error);
      return error;
    }

    try {
      const payload = {
        build: "112",
        deal_id: String(data.id),
        deal_unique_id: "",
        is_testing_live: "0",
        language_id: "2",
        user_id: String(data.user_id),
        version: "6.0",
      };
      const success = await axios.post(
        `${BASE_API_URL}A_GetDealDetails`,
        payload
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};

export const clearDealDetails = () => {
  console.log("clearDealDetails");
  return (dispatch) => {
    dispatch({
      type: CLEAR_DEAL_DETAILS,
    });
  };
};

// export const getCurrentDeal = ({ data, callback }) => {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       // dispatch({ type: ORDER_PAYMENT_REQUEST });
//       // console.log("getCurrentDeal start");
//       axios
//         // .post(`${BASE_API_URL}A_GetLinkForPayment`, data)
//         .post(
//           "https://ndev.posh-market.ru/App/LiveAPI/API/HiShoppersService.php?Service=A_GetDealDetails",

//           data
//         )
//         .then((res) => {
//           console.log("THIS ", res.data.data);
//           callback(res.data.data);
//           resolve(res.data.data);
//         })
//         .catch((err) => {
//           // console.log("getCurrentDeal error ", err);
//           reject(err);
//         });
//     });
//   };
// };

// ------------------

/** Pay from order page Original */
export const payFromDeals = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: ORDER_PAYMENT_REQUEST });
      axios
        // .post(`${BASE_API_URL}A_GetLinkForPayment`, data)
        .post(
          `${NEW_API_URL}A_GetLinkForPayment`,
          {
            deal_id: 92118,
            // pickup_address: "0JvQtdC90LjQvdGB0LrQuNC5INC/0YAt0LrRgiwgODUsIDE=",
            // pickup_pvz: "KLD6",
            // user_id: 252182,
          },
          data
        )
        .then((res) => {
          // console.log("paymentLink >>> ", res);
          dispatch({
            type: ORDER_PAYMENT_SUCCESS,
            // payload: res.data.data,
            payload: { data: res.data.data, dealId: data.deal_id },
            // payload: {...res.data.data, ...data.deal_id},
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: ORDER_PAYMENT_ERROR,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

/** Tester function to open external link */
// export const payFromDeals = () => {
//     return (dispatch) => {
//         return new Promise((resolve, reject) => {
//             resolve({ link: 'https://www.w3schools.com/' });
//         })
//     }
// }

export const paymentStatus = (dealId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_CheckOrderStatusIsPaid`, dealId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const cancelPayment = (dealId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_CancelPaymentNotPaid`, dealId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const updatePayment = (dealId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}A_UpdatePaymentCompleted`, dealId)
        .then((res) => {
          console.log("res - ", res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const setSelectDeal = (deal) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECT_DEAL,
      payload: deal,
    });
  };
};

export const clearSelectDeal = (deal) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECT_DEAL,
    });
  };
};
