import { toast } from 'react-toastify';

export const success = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
    });
}

export const fail = (msg, timer) => {
    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: timer ? timer : '',
    });
}
