import React from "react";
import { withRouter, Link } from "react-router-dom";
import Logo from "./../../shared/images/icons/icon-logo.svg";
import "./HeaderLogo.scss";

const HeaderLogo = () => {
  return (
    <div className='logo-bg'>
      <div className='container'>
        <Link
          to='/'
          className='navbar-brand'
          //   onClick={() => this.navigate()}
        >
          <img src={Logo} alt='posh-app-logo' />
        </Link>
      </div>
    </div>
  );
};
export default withRouter(HeaderLogo);
