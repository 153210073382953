import React, { FC, ReactElement, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { BaseButton, BasePopover, IconInfo, TimeLine } from "..";
import orderStatus from "../../models/orderStatus";

import { calcStatusTitle } from "./../../helpers/calcStatusTitle";
// import { excludedSteps } from "./../../helpers/excludedSteps";

import { postCancelDeal } from "../../../api/post-cancel-deal";

import "./order-item.scss";
import moment from "moment";
import "moment/locale/ru";
import { getAllDeals, modalOpen, setSelectDeal } from "../../../store/actions";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";

moment.locale("ru");

interface Props {
  isCompact?: boolean;
  inModal?: boolean;
  order: any;
  userInfo?: any;
  onClick?: () => void;
}

export const OrderItem: FC<Props> = ({
  isCompact,
  inModal,
  order,
  userInfo,
  onClick,
}): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const PopoverContent = (step: number) => (
    <TimeLine
      activeStep={step}
      isAuthentication={!!order?.eligible_admin_approval}
    />
  );

  const onCancelDeal = async () => {
    setIsLoading(true);
    await postCancelDeal({
      deal_id: order.deal_id,
      // cancellation_reason: window.btoa(
      // unescape(encodeURIComponent("formBio.address.value"))
      // ),
      cancellation_reason: unescape(encodeURIComponent("Отмена")),
      user_id: userInfo.user_id,
    })
      .then(() => {
        if (history.location.pathname !== "/orders") {
          history.push("/orders?current");
        }
        dispatch(modalOpen("delete-deal-success"));
        updateDeals();
      })
      .catch(() => {
        dispatch(modalOpen("delete-deal-error"));
      });
    setIsLoading(false);
  };

  const updateDeals = () => {
    dispatch(
      getAllDeals({
        user_id: userInfo.user_id,
      })
    );
  };

  const goodsReceived = () => {
    dispatch(setSelectDeal(order));
    dispatch(modalOpen("good-received"));
  };

  const rateDeal = () => {
    dispatch(setSelectDeal(order));
    dispatch(modalOpen("rate-deal"));
  };

  const payBtnVisible: boolean = useMemo(() => {
    if (isLoading || inModal) return false;

    if (order?.eligible_admin_approval) {
      return order.status === 3;
    }

    return order.status === 3 || order.status === 27;
  }, [inModal, isLoading, order]);

  const cancelBtnVisible: boolean = useMemo(() => {
    if (isLoading || inModal) return false;

    if (order?.eligible_admin_approval) {
      return order.status === 1 || order.status === 3;
    }

    return order.status === 1 || order.status === 3 || order.status === 27;
  }, [inModal, isLoading, order]);

  const receivedBtnVisible: boolean = useMemo(() => {
    if (isLoading || inModal) return false;

    if (order?.eligible_admin_approval) {
      return order.status === 8;
    }

    return order.status === 5 || order.status === 23 || order.status === 25;
  }, [inModal, isLoading, order]);

  const closeBtnVisible: boolean = useMemo(() => {
    if (isLoading) return false;

    return order.status === 9;
  }, [isLoading, order]);

  return (
    <div
      className={classNames("order-item", {
        "order-item--column": isCompact,
        "order-item--in-modal": inModal,
      })}
    >
      {isCompact && !inModal && (
        <div className='order-item__date order-item__date--compact'>
          Сделка от {moment(order.created_date).format("DD MMMM, YYYY")}
        </div>
      )}

      <div className='order-item__box order-item__box--info'>
        <div
          className='order-item__image'
          style={{
            backgroundImage: `url(${
              order.products[0].product_original_url.split(",")[0] || ""
            })`,
          }}
        />
        <div className='order-item__box-col'>
          {!inModal && (
            <div className='order-item__box-row'>
              {!isCompact && (
                <Link
                  to={`/order/${order.deal_id}`}
                  className='order-item__date'
                >
                  Сделка от{" "}
                  {moment(order.created_date).format("DD MMM M, YYYY")}
                </Link>
              )}
              <div
                className={classNames("order-item__status", {
                  "order-item__status--shift": !isCompact,
                })}
              >
                <div className='order-item__status-text'>
                  {calcStatusTitle(
                    order?.status,
                    order?.eligible_admin_approval
                  )}
                </div>
                {order && !inModal && (
                  <BasePopover
                    content={PopoverContent(order.status)}
                    trigger='hover'
                  >
                    <IconInfo />
                  </BasePopover>
                )}
              </div>
            </div>
          )}

          <div className='order-item__price'>
            {order.products[0].sell_for} ₽
          </div>
          {!!order.delivery_charges && (
            <div className='order-item__price order-item__price--discount'>
              {order.products[0].bought_for} ₽
            </div>
          )}

          <div className='order-item__name'>
            {order.products[0].sub_category_name} {order.products[0].brand_name}
          </div>
          <div className='order-item__size'>
            Размер: {order.products[0].sizename}
          </div>
        </div>
      </div>

      <div
        className={classNames("order-item__box order-item__box--payment", {
          "order-item__box--payment-compact": isCompact,
        })}
      >
        {isLoading && <Loader inLine />}

        {payBtnVisible && (
          <BaseButton
            to={`/checkout/${order.deal_id}`}
            secondary
            className='order-item__btn'
          >
            ОПЛАТИТЬ
          </BaseButton>
        )}

        {cancelBtnVisible && (
          <BaseButton
            secondary
            className='order-item__btn'
            onClick={onCancelDeal}
          >
            ОТМЕНИТЬ
          </BaseButton>
        )}

        {receivedBtnVisible && !inModal && (
          <BaseButton
            secondary
            className='order-item__btn'
            onClick={goodsReceived}
          >
            ТОВАР ПОЛУЧЕН
          </BaseButton>
        )}

        {closeBtnVisible && (
          <BaseButton secondary className='order-item__btn' onClick={rateDeal}>
            ЗАКРЫТЬ СДЕЛКУ
          </BaseButton>
        )}
      </div>
    </div>
  );
};
