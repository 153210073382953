
export const FILTER_CATEGORY_BY_GENDER = 'FILTER_CATEGORY_BY_GENDER'
export const FILTER_SIZE_BY_CATEGORY = 'FILTER_SIZE_BY_CATEGORY'
export const RESET_SIDEBAR = 'RESET_SIDEBAR'
export const RESET_ALL_STATE = 'RESET_ALL_STATE'

export const GET_SIDEBAR_REQUEST = 'GET_SIDEBAR_REQUEST'
export const GET_SIDEBAR_SUCCESS = 'GET_SIDEBAR_SUCCESS'
export const GET_SIDEBAR_ERROR = 'GET_SIDEBAR_ERROR'

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'

export const GET_FILTER_PRODUCTS_REQUEST = 'GET_FILTER_PRODUCTS_REQUEST'
export const GET_FILTER_PRODUCTS_SUCCESS = 'GET_FILTER_PRODUCTS_SUCCESS'
export const GET_FILTER_PRODUCTS_ERROR = 'GET_FILTER_PRODUCTS_ERROR'

export const GET_PRODUCT_DETAILS_REQUEST = 'GET_PRODUCT_DETAILS_REQUEST'
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS'
export const GET_PRODUCT_DETAILS_ERROR = 'GET_PRODUCT_DETAILS_ERROR'

export const INITIAT_DEAL_REQUEST = 'INITIAT_DEAL_REQUEST'
export const INITIAT_DEAL_SUCCESS = 'INITIAT_DEAL_SUCCESS'
export const INITIAT_DEAL_ERROR = 'INITIAT_DEAL_ERROR'

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'