import React, { FC, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import "./base-tabs.scss";

export interface ITab {
  label: string;
  url?: string;
  active?: boolean;
}

export interface ITabComplex {
  key: string;
  items: ITab[];
}

export interface IBaseTabs {
  items: ITab[];
  connectWithUrl?: boolean;
  compact?: boolean;
  fluid?: boolean;
  tabs?: ReactElement[];
  headerClass?: string;
  wrapperClass?: string;
  onChange?: (item: ITab) => any;
}

export const BaseTabs: FC<IBaseTabs> = ({
  items,
  fluid,
  connectWithUrl,
  compact,
  tabs,
  headerClass,
  wrapperClass,
  onChange,
}): ReactElement => {
  const history = useHistory();

  const onClickHandler = (item: ITab) => {
    if (connectWithUrl) {
      history.push({
        search: item.url,
      });
    } else {
      onChange && onChange(item);
    }
  };

  return (
    <div>
      <ul
        className={classNames("base-tabs", headerClass, {
          "base-tabs--compact": compact,
        })}
      >
        {items.map((item: ITab, index: number) => (
          <li
            key={index}
            onClick={() => onClickHandler(item)}
            className={classNames("base-tabs__label", {
              "base-tabs__label--active": item.active,
              "base-tabs__label--fluid": fluid,
              "base-tabs__label--compact": compact,
            })}
          >
            {item.label}
          </li>
        ))}
      </ul>
      {tabs && (
        <ul className={`base-tabs__wrapper ${wrapperClass}`}>
          {tabs?.map((item: any, index: number) => (
            <li
              key={index}
              className={classNames("base-tabs__tab", {
                "base-tabs__tab--active": items[index].active,
              })}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
