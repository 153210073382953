import axios from "axios";
import { NEW_API_URL } from "../global/ApiConfig";
import { setHttpConfig } from "../shared/helpers/setHttpConfig";

export const postProfile = async (payload: {
  user_id: any;
  first_name: any;
  last_name: any;
  nick_name: any;
  device_token: any;
  email: any;
  city_id: any;
  additional_phone?: any;
}): Promise<any> => {
  const { data } = await axios.post(
    `${NEW_API_URL}/user/profile`,
    payload,
    setHttpConfig(localStorage.getItem("_token"))
  );
  return data.data;
};
