import axios from "axios";
import { NEW_API_URL } from "../global/ApiConfig";
import { setHttpConfig } from "../shared/helpers/setHttpConfig";

export const postSmsCode = async (payload: {
  contact_number: any;
  country_prefix: string;
  verification_code: any;
}): Promise<any> => {
  return await axios
    .post(
      `${NEW_API_URL}/login/verify-code`,
      payload,
      setHttpConfig(localStorage.getItem("_token"))
    )
    .then((response) => {
      return response.data.data;
    })
    .catch(() => {
      return null;
    });
};
