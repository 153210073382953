import React, { FC, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";

import "./time-line.scss";

interface Props {
  activeStep: number;
  isAuthentication: boolean;
}

export const TimeLine: FC<Props> = ({
  activeStep,
  isAuthentication,
}): ReactElement => {
  const [step, setStep] = useState<number>(activeStep);
  const [list, setList] = useState<any>(null);

  const excludedStep =
    activeStep !== 1 &&
    activeStep !== 2 &&
    activeStep !== 3 &&
    activeStep !== 4 &&
    activeStep !== 5 &&
    activeStep !== 8 &&
    activeStep !== 26 &&
    activeStep !== 23 &&
    activeStep !== 25 &&
    activeStep !== 9 &&
    activeStep !== 10 &&
    activeStep !== 12 &&
    activeStep !== 27 &&
    activeStep !== 7;

  useEffect(() => {
    if (excludedStep) {
      return setStep(1);
    } else if (!isAuthentication) {
      if (activeStep === 1) {
        setStep(1);
      }
      if (activeStep === 3 || activeStep === 27) {
        setStep(2);
      }
      if (activeStep === 4) {
        setStep(3);
      }
      if (activeStep === 5 || activeStep === 26) {
        setStep(4);
      }
      if (activeStep === 23) {
        setStep(5);
      }
      if (activeStep === 9) {
        setStep(6);
      }
      if (activeStep === 10) {
        setStep(7);
      }
      if (activeStep === 12) {
        setStep(8);
      }
    } else if (isAuthentication) {
      if (activeStep === 1) {
        setStep(1);
      }
      if (activeStep === 3) {
        setStep(2);
      }
      if (activeStep === 4) {
        setStep(3);
      }
      if (activeStep === 5 || activeStep === 26) {
        setStep(4);
      }
      if (activeStep === 25) {
        setStep(5);
      }
      if (activeStep === 7) {
        setStep(6);
      }
      if (activeStep === 8) {
        setStep(7);
      }
      if (activeStep === 9) {
        setStep(8);
      }
      if (activeStep === 10) {
        setStep(9);
      }
      if (activeStep === 12) {
        setStep(10);
      }
    }
  }, [activeStep, isAuthentication]);

  useEffect(() => {
    if (excludedStep) {
      setList([
        {
          label: "Сделка отменена",
        },
      ]);
    } else if (!isAuthentication) {
      setList([
        {
          label: "Подтверждение наличия",
          description: "Ожидайте подтверждения наличия товара",
        },
        {
          label: "Наличие товара подтверждено",
          description: "Оплатите товар",
        },
        {
          label: "Товар оплачен",
          description: "Ожидайте отправку товара продавцом",
        },
        {
          label: "Курьер заказан",
          description: "Ожидайте отправку товара продавцом",
        },
        {
          label: "Товар передан курьеру",
          description: "Ожидайте доставки в POSH MARKET",
        },
        {
          label: "Товар доставлен",
          description: "Закройте сделку и оцените товар",
        },
        {
          label: "Оценка сделки",
          description: "Ожидайте оценку сделки продавцом",
        },
        {
          label: "Сделка закрыта",
          description: "До новых встреч :-)",
        },
      ]);
    } else if (isAuthentication) {
      setList([
        {
          label: "Подтверждение наличия",
          description: "Ожидайте подтверждения наличия товара",
        },
        {
          label: "Наличие товара подтверждено",
          description: "Оплатите товар",
        },
        {
          label: "Товар оплачен",
          description: "Заказ оплачен. Ожидайте отправку товара продавцом",
        },
        {
          label: "Курьер заказан",
          description: "Ожидайте доставки в POSH MARKET",
        },
        {
          label: "Товар передан курьеру",
          description: "Ожидайте доставки в POSH MARKET",
        },
        {
          label: "Проверка подлинности",
          description: "Ожидайте прохождение проверки",
        },
        {
          label: "Проверка пройдена",
          description: "Ожидайте доставки",
        },

        {
          label: "Товар доставлен",
          description: "Закройте сделку и оцените товар",
        },
        {
          label: "Оценка сделки",
          description: "Ожидайте оценку сделки продавцом",
        },
        {
          label: "Сделка закрыта",
          description: "До новых встреч :-)",
        },
      ]);
    }
  }, [isAuthentication]);

  return (
    <div className='time-line'>
      {step &&
        list &&
        list.map((item: any, index: number) => (
          <div
            key={index}
            className={classNames("time-line__step", {
              "time-line__step--active": step > index,
              "time-line__step--current": step === index + 1,
            })}
          >
            <div
              className={classNames("time-line__step-label", {
                "time-line__step-label--active": step < index + 1,
              })}
            >
              {item.label}
            </div>
            {item.description && (
              <div className='time-line__step-description'>
                {item.description}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
