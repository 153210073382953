import { MODAL_OPEN, MODAL_CLOSE } from "../actionTypes";

export const modalOpen = (name) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_OPEN,
      payload: name,
    });
  };
};

export const modalCloseAll = () => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CLOSE,
    });
  };
};
