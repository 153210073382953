export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

export const GET_ALL_CITY_REQUEST = 'GET_ALL_CITY_REQUEST'
export const GET_ALL_CITY_SUCCESS = 'GET_ALL_CITY_SUCCESS'
export const GET_ALL_CITY_ERROR = 'GET_ALL_CITY_ERROR'



