import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./store/reducers/rootReducer";

const middleware = [thunk];

process.env.NODE_ENV === "_development" && middleware.push(logger);

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
