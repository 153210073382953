import axios from "axios";
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  SET_DELIVERY_ADDRESS,
  GET_ALL_CITY_REQUEST,
  GET_ALL_CITY_SUCCESS,
  GET_ALL_CITY_ERROR,
} from "../actionTypes";

import { BASE_API_URL, NEW_API_URL } from "../../global/ApiConfig";
import { setHttpConfig } from "../../shared/helpers/setHttpConfig";

/** Get user details */
export const getUserDetail = (token) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: GET_USER_REQUEST });
      axios
        .get(`${BASE_API_URL}/user/details`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-API-KEY":
              "dsfugfew9fg9ew8f7g9ew87gfew87fgew98f7gew87fgwe98fgiwegkywkad0wqs2bpi",
            Authorization: `Bearer ${token.token}`,
          },
        })
        .then((res) => {
          dispatch({
            type: GET_USER_SUCCESS,
            payload: res.data.data[0],
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: GET_USER_ERROR,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

/** Get All City */
export const getAllCity = () => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_CITY_REQUEST });
    axios
      .get(
        `${NEW_API_URL}/cities`,
        setHttpConfig(localStorage.getItem("_token")),
        { limit: 10 }
      )
      .then((res) => {
        // console.log("получил города ", res);
        dispatch({
          type: GET_ALL_CITY_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_CITY_ERROR,
          payload: err,
        });
      });
  };
};

export const searchCityNow = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_API_URL}SearchCity`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const checkUserNameExist = (data) => {
  return (dispatch) => {
    return new Promise((resolev, reject) => {
      axios
        .post(`${BASE_API_URL}CheckUsernameExists`, data)
        .then((res) => {
          // console.log(">>>> ", res.data.message);
          resolev(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const setDeliveryAddress = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_DELIVERY_ADDRESS });
    axios.post(`${BASE_API_URL}V3SaveAddress`, data).then((res) => {
      // console.log("адрес установился");
    });
  };
};
