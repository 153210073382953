/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Logo from "../../images/logo.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <a href='#' id='bottom-button'></a>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='footer-left'>
              <div className='image'>
                <img src={Logo} alt='posh-app-logo' />
              </div>
              <p>
                Покупайте и продавайте на POSH MARKET. Проверка подлинности,
                безопасная сделка и доставка лично в руки.
              </p>
              <ul className='app-icon'>
                <li>
                  <a
                    href='https://posh-market.onelink.me/J8mW/b90b0697'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='fa fa-apple'
                  ></a>
                </li>
                <li>
                  <a
                    href='https://posh-market.onelink.me/J8mW/b90b0697'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='fa fa-android'
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-12 col-md-5 col-lg-4 ml-auto'>
            <div className='footer-right'>
              {/* <h4>Customer service</h4> */}
              <h4>Служба поддержки</h4>
              <p>
                <i className='fa fa-phone' /> Телефон :
                <a href='tel:+78007072542'>+7 (800) 707-25-42</a>
              </p>
              <p>
                <i className='fa fa-envelope' />
                <a href='mailto:support@posh-market.ru'>
                  support@posh-market.ru
                </a>
              </p>
              <p>
                <i className='fa fa-clock-o' /> Время работы :
                <br />
                Рабочие дни 10 :00 - 18 :00
                {/* <br />
                  Sat - Sun: Day off */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
