import React, { FC, ReactElement, useState } from "react";
import { BaseButton, BasePopover, CardBanner } from "../";
import { ICardBannerColor, ICardBannerIcon } from "../card-banner/card-banner";
import { BaseSwitch } from "../form-fields";
import { ButtonGroup } from "../button-group/button-group";
import { Link, useHistory } from "react-router-dom";
import { IconInfo } from "../icons/icon-info/icon-info";

import { modalOpen, drawerOpen, createDeal } from "../../../store/actions";

import "./../../scss/modules/product-info.scss";
import { useDispatch, useSelector } from "react-redux";
import { isUserLogin } from "../../../global/GetToken";

export enum EBaseAlertType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}
interface Props {
  onClose?: () => void;
  setIsPayedAuth?: (val: any) => any;
  product: any;
}

export const ProductInfo: FC<Props> = ({
  onClose,
  product,
  setIsPayedAuth,
}): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore
  const profileData = useSelector((state) => state.profile.data);

  const [switchValue, setSwitchValue] = useState<boolean>(false);

  const onChangeHandleSwitch = (): void => {
    setSwitchValue(!switchValue);
    // if (!!switchValue) {
    !!setIsPayedAuth && setIsPayedAuth(switchValue);
    // }
    // dispatch(setBasket({ identity: !switchValue }));
  };

  // const handleAuthSubmit = () => {
  //   history.push("/login");
  // };

  const PopoverContent = () => (
    <div>
      <div className='popover-info'>
        Проверка подлинности осуществляется экспертами Posh Market, сервисом
        Entrupy, а также разными мировыми сервисами, оказывающими услуги по
        аутентификации брендовых вещей
      </div>
      Для проверки товар сначала доставляется в Posh Market, а поссле ее
      прохождения — покупателю. Проверка занимает до пяти рабочих дней, но в
      большинстве случаев один. Для товаров дороже 20 000 ₽ проверка подлинности
      бесплатна и проводится обязательно. Для остальных товаров проверку мождно
      заказать, как допонительную услугу
    </div>
  );

  return (
    <div className='product-info'>
      <div className='product-info__col'>
        <div className='product-info__code'>{product?.product_unique_id}</div>
        <div className='product-info__row product-info__row--with-dash'>
          <span className='product-info__category'>
            {product?.sub_category_name}
          </span>
          <span className='product-info__name'>{product?.brand_name}</span>
        </div>
        <div className='product-info__row product-info__row--with-price'>
          <span className='product-info__price'>{product?.sell_for}₽</span>
          {!!product?.bought_for && (
            <span className='product-info__discount'>
              {product?.bought_for}₽
            </span>
          )}
        </div>
        <table className='product-info__table'>
          <tbody>
            <tr className='product-info__table-row'>
              <td className='product-info__table-cell'>Раздел</td>
              <td className='product-info__table-cell product-info__table-cell--bold'>
                {product?.mainCatType}
              </td>
            </tr>
            <tr className='product-info__table-row'>
              <td className='product-info__table-cell'>Категория</td>
              <td className='product-info__table-cell product-info__table-cell--bold'>
                {product?.sub_category_name}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='product-info__delimiter'></div>
        <table className='product-info__table'>
          <tbody>
            <tr className='product-info__table-row'>
              <td className='product-info__table-cell'>
                Размер
                {/* <BasePopover
                  placement='top'
                  content={<>Какая-то подсказка по размерам</>}
                >
                  <IconInfo />
                </BasePopover> */}
              </td>
              <td className='product-info__table-cell product-info__table-cell--bold'>
                {product?.sizename}
              </td>
            </tr>
            <tr className='product-info__table-row'>
              <td className='product-info__table-cell'>Цвет</td>
              <td className='product-info__table-cell product-info__table-cell--bold'>
                {product?.name}
              </td>
            </tr>
            <tr className='product-info__table-row'>
              <td className='product-info__table-cell'>Состояние</td>
              <td className='product-info__table-cell product-info__table-cell--bold'>
                {product?.pro_use_text_russ}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='product-info__brand-logo'>{product?.brand_name}</div>
        <CardBanner
          color={ICardBannerColor.BLUE}
          icon={ICardBannerIcon.AUTHENTICITY_OF_GOODS}
          title={"Подлинность товара"}
          tooltip={PopoverContent()}
          description={"Мы проверим подлинность этого товара"}
        >
          <BaseSwitch
            id={"card-switch"}
            value={switchValue}
            onChange={onChangeHandleSwitch}
          />
          <div className='card-banner__price'>1 500 ₽</div>
        </CardBanner>
        <div className='product-info__button'>
          {!profileData?.username && (
            <BaseButton
              block
              primary
              onClick={() => dispatch(modalOpen("auth-phone"))}
            >
              запросить наличие
            </BaseButton>
          )}
          {!!profileData?.username && (
            <BaseButton
              block
              primary
              onClick={() => dispatch(modalOpen("availability-request"))}
            >
              запросить наличие
            </BaseButton>
          )}
        </div>
        <div className='product-info__link'>
          <span className='product-info__link-text'>Что такое</span>
          <span
            className='product-info__link-text product-info__link-text--bold'
            onClick={() => dispatch(drawerOpen())}
          >
            «Запросить наличие»?
          </span>
        </div>
      </div>
    </div>
  );
};
