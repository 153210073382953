import axios from "axios";
import { BASE_API_URL } from "../global/ApiConfig";

export const postAddress = async (payload: {
  user_id: string;
  city_id: string;
  address: string;
}): Promise<any> => {
  const data = await axios.post(`${BASE_API_URL}V3SaveAddress`, payload);
  return data;
};
