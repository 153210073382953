import axios from "axios";
import { BASE_API_URL } from "../global/ApiConfig";

export const postProductsReceivedByBuyer = async (payload: {
  deal_id: any;
}): Promise<any> => {
  const data = await axios.post(
    `${BASE_API_URL}A_ProductsReceivedByBuyer`,
    payload
  );
  return data;
};
