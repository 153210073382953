import { X_API_KEY_PROD } from "../../global/ApiConfig";

export const setHttpConfig = (token: string | any): any => {
  let config: any = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-API-KEY": X_API_KEY_PROD,
    },
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};
