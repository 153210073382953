import React, { FC, ReactElement, useState } from "react";
import { BaseButton, BasePopover, CardBanner } from "..";

import { modalOpen } from "../../../store/actions";

import "./../../scss/modules/product-info.scss";
import { useDispatch } from "react-redux";

export enum EBaseAlertType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}
interface Props {
  onClose?: () => void;
  product: any;
}

export const ProductInfoMini: FC<Props> = ({
  onClose,
  product,
  children,
}): ReactElement => {
  return (
    <div className='product-info'>
      <div className='product-info__row product-info__row--with-dash'>
        <span className='product-info__category product-info__category--small'>
          {product?.sub_category_name}
        </span>
        <span className='product-info__name product-info__name--small'>
          {product?.brand_name}
        </span>
      </div>

      <div className='product-info__delimiter product-info__delimiter--full-width'></div>

      <table className='product-info__table product-info__table--full-width'>
        <tbody>
          <tr className='product-info__table-row'>
            <td className='product-info__table-cell'>Категория</td>
            <td className='product-info__table-cell'>Цвет</td>
          </tr>
          <tr className='product-info__table-row'>
            <td className='product-info__table-cell product-info__table-cell--bottom-offset product-info__table-cell--bold'>
              {product?.sub_category_name}
            </td>
            <td className='product-info__table-cell product-info__table-cell--bottom-offset product-info__table-cell--bold'>
              {product?.name}
            </td>
          </tr>
          <tr className='product-info__table-row'>
            <td className='product-info__table-cell'>Состояние</td>
            <td className='product-info__table-cell'>Бренд</td>
          </tr>
          <tr className='product-info__table-row'>
            <td className='product-info__table-cell product-info__table-cell--bold'>
              {product?.pro_use_text_russ}
            </td>
            <td className='product-info__table-cell product-info__table-cell--bold'>
              {product?.brand_name}
            </td>
          </tr>
        </tbody>
      </table>

      <div className='product-info__delimiter product-info__delimiter--full-width'></div>

      <div className='product-info__row product-info__row--offset-top product-info__row--with-price'>
        <span className='product-info__price'>{product?.sell_for}₽</span>
        {!!product?.bought_for && (
          <span className='product-info__discount'>{product?.bought_for}₽</span>
        )}
      </div>

      {children}
    </div>
  );
};
