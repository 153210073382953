import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import user from "./user";
import product from "./product";
import deal from "./deal";
import blog from "./blog";
import home from "./home";
import modal from "./modal";
import drawer from "./drawer";
import profile from "./profile";

export default combineReducers({
  auth,
  user,
  product,
  deal,
  blog,
  home,
  modal,
  drawer,
  profile,
  form: formReducer,
});
