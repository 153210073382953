import React, { FC, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import "./base-radio.scss";

// export enum DeliveryType {
//   COURIER = "Courier",
//   PICKUP = "pickup",
// }

export interface IRadio {
  // type:
  icon: string;
  label: string;
  price: number;
  active?: boolean;
}

export interface IBaseRadio {
  className?: string;
  items: IRadio[];
  onChange?: (index: number) => any;
}

export const BaseRadio: FC<IBaseRadio> = ({
  className,
  items,
  onChange,
}): ReactElement => {
  const onClickHandler = (index: number) => {
    onChange && onChange(index);
  };

  return (
    <ul className={`${className} base-radio`}>
      {items.map((item: IRadio, index: number) => (
        <li
          key={index}
          onClick={() => onClickHandler(index)}
          className="base-radio__item"
        >
          <div className="base-radio__box base-radio__box--fluid">
            <div
              className={`base-radio__label base-radio__label--${item.icon}`}
            >
              {item.label}
            </div>
            <div className="base-radio__price">
              {item.price.toLocaleString()}
            </div>
          </div>
          <div className="base-radio__box base-radio__box--center">
            <div
              className={classNames("base-radio__circle", {
                "base-radio__circle--active": item.active,
              })}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};
