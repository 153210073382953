import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import { IBaseError } from "../../models/error";

import "./base-error.scss";

export interface Props {
  type?: IBaseError;
  icon?: IBaseError;
  text: string;
}

export const BaseError: FC<Props> = ({
  type = "description",
  icon,
  text,
}): ReactElement => {
  return (
    <div
      className={classNames(`base-error base-error--${type}`, {
        "base-error--no-icon": !icon,
      })}
    >
      {icon && (
        <span className={`base-error__icon base-error__icon--${icon}`} />
      )}
      {text}
    </div>
  );
};
