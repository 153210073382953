import classNames from "classnames";

import React, {
  FormEvent,
  FC,
  ReactElement,
  Fragment,
  useEffect,
  useState,
} from "react";

// @ts-ignore
import { Link } from "react-router-dom";

import "./base-button.scss";

export interface IBaseButtonProps {
  submit?: boolean;
  block?: boolean;

  primary?: boolean;
  secondary?: boolean;

  disabled?: boolean;
  to?: string;
  onClick?: (() => void) | ((e: FormEvent) => void);
  className?: string;
}

export const BaseButton: FC<IBaseButtonProps> = ({
  children,
  submit,
  block,
  primary,
  secondary,
  disabled,
  to,
  onClick,
  className,
}): ReactElement => {
  const [classes, setClasses] = useState<string>("button");

  useEffect(() => {
    setClasses(
      classNames("button", className, {
        "button--block": block,
        "button--secondary": secondary,
        "button--primary": primary,
        "button--disabled": disabled,
      })
    );
  }, [children, block, primary, disabled, secondary, className, to]);

  return (
    <Fragment>
      {to && (
        <Link to={to} className={classes}>
          <span className='button__content'>{children}</span>
        </Link>
      )}
      {!to && (
        <button
          disabled={disabled}
          type={submit ? "submit" : "button"}
          className={classes}
          onClick={onClick}
        >
          <span className='button__content'>{children}</span>
        </button>
      )}
    </Fragment>
  );
};
