import axios from "axios";
import { BASE_API_URL } from "../global/ApiConfig";

export const postRateDeal = async (payload: {
  deal_id: any;
  rating_by: any;
  ratings: any;
  comment: any;
}): Promise<any> => {
  const bodyFormData = new FormData();

  bodyFormData.append("deal_id", payload.deal_id);
  bodyFormData.append("rating_by", payload.rating_by);
  bodyFormData.append("ratings", payload.ratings);
  bodyFormData.append("comment", payload.comment);

  const data = await axios.post(`${BASE_API_URL}A_RateDeal`, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
