import React, { FC, ReactElement } from "react";
// @ts-ignore
import _ from "lodash";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";

import "./product-card.scss";
import { Link } from "react-router-dom";

interface Props {
  product: any;
}

export const ProductCard: FC<Props> = ({ product }): ReactElement => {
  const history = useHistory();

  const navigate = (product: any) => {
    let product_cat_eng = "";
    switch (product.category_name.toLowerCase().trim()) {
      case "одежда":
        product_cat_eng = "clothes";
        break;
      case "обувь":
        product_cat_eng = "shoes";
        break;
      case "сумки":
        product_cat_eng = "bags";
        break;
      case "аксессуары":
        product_cat_eng = "accessories";
        break;
      case "косметика и духи":
        product_cat_eng = "perfume and make up";
        break;

      default:
        product_cat_eng = product.category_name;
        break;
    }

    let main_cat = _.kebabCase(
      `${product.mainCatType}-${product_cat_eng}`
    ).toLowerCase();
    let sub_cat = _.kebabCase(`${product.sub_category_name_eng}`).toLowerCase();
    let b_name = _.kebabCase(`${product.brand_name_eng}`).toLowerCase();
    let productId = product.product_id;
    let color = _.kebabCase(
      `${product.color_name_eng}-${product.sub_category_name_eng}-${productId}`
    ).toLowerCase();
    history.push(`/${main_cat}/${sub_cat}/${b_name}/${color}`);
  };

  return (
    <div onClick={() => navigate(product)} className='product-card'>
      <div
        className='product-card__image'
        style={{
          backgroundImage: `url(${product?.product_share_url})`,
        }}
      />
      <div className='product-card__content'>
        <div className='product-card__info'>
          <div className='product-card__brand'>{product.name}</div>
          <div className='product-card__name'>{product.mainCatType}</div>
        </div>
        <div className='product-card__price'>
          {product.seller_earnings.toLocaleString()}
        </div>
      </div>
    </div>
  );
};
