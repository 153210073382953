import React, { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./card-banner.scss";
import { IconInfo } from "../icons/icon-info/icon-info";
import { BasePopover } from "..";
import { drawerOpen } from "../../../store/actions";

export enum ICardBannerColor {
  "GREEN" = "green",
  "BLUE" = "blue",
  "GRAY" = "gray",
}

export enum ICardBannerIcon {
  "SAFE_DEAL" = "safe-deal",
  "AUTHENTICITY_OF_GOODS" = "authenticity-of-goods",
}

interface Props {
  color?: ICardBannerColor;
  icon?: ICardBannerIcon;
  title: string;
  description: string;
  link?: string;
  linkText?: string;
  tooltip?: ReactElement;
  slotRight?: boolean;
  className?: string;
}

export const CardBanner: FC<Props> = ({
  color = ICardBannerColor.GRAY,
  icon,
  title,
  description,
  link,
  linkText,
  tooltip,
  children,
  slotRight,
  className,
}): ReactElement => {
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(className, "card-banner", `card-banner--${color}`)}
    >
      {icon && (
        <div
          className={classNames(
            "card-banner__icon",
            `card-banner__icon--${icon}`
          )}
        />
      )}
      <div className='card-banner__content'>
        <div className='card-banner__coll card-banner__coll--left'>
          {title && (
            <div className='card-banner__title'>
              {title}
              {tooltip && (
                <BasePopover
                  content={tooltip}
                  trigger='click'
                  title='Проверка подлинности'
                >
                  <IconInfo className='icon-info--orange' />
                </BasePopover>
              )}
            </div>
          )}
          {children && !slotRight && (
            <div className='card-banner__slot'>{children}</div>
          )}
          {description && (
            <div
              className={classNames("card-banner__description", {
                "card-banner__description--has-children": children,
              })}
            >
              {description}
            </div>
          )}
          {link && (
            <Link to={link} className='card-banner__link'>
              {linkText}
            </Link>
          )}
          {!link && (
            <div
              onClick={() => dispatch(drawerOpen())}
              className='card-banner__link card-banner__link--no-arrow'
            >
              {linkText}
            </div>
          )}
        </div>
        {children && slotRight && (
          <div className='card-banner__coll card-banner__coll--right'>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
