import React from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeaderLogo from "../components/HeaderLogo";
import { decrypt } from "./JWT";

const PrivateRoute = ({
  name,
  component: Component,
  showHeader,
  showFooter,
  ...rest
}) => {
  let token = localStorage.getItem("token");
  let isLogin = !!localStorage.getItem("_token");
  // let isLogin = token ? (decrypt(token).dump ? true : false) : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <>
            {showHeader ? <Header /> : <HeaderLogo />}
            <Component {...props} />
            {showFooter ? <Footer /> : null}
          </>
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

const PublicRoute = ({
  name,
  component: Component,
  showHeader,
  showFooter,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        {showHeader ? <Header /> : <HeaderLogo />}
        <Component {...props} />
        {showFooter ? <Footer /> : null}
      </>
    )}
  />
);

export { PrivateRoute, PublicRoute };
