import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import "./base-list.scss";

export interface IBaseListItem {
  active?: boolean;
  title: string[];
  description: string[];
}

export interface IBaseList {
  className?: string;
  titles: string[];
  items: any;
  // items: IBaseListItem[];
  onChange?: (index: number) => any;
}

export const BaseList: FC<IBaseList> = ({
  className,
  titles,
  items,
  onChange,
}): ReactElement => {
  const onClickHandler = (index: number) => {
    onChange && onChange(index);
  };

  return (
    <div className={`base-list ${className}`}>
      <div className='base-list__head'>
        <div className='base-list__row'>
          {titles.map((title: string, index: number) => (
            <div key={index} className='base-list__col base-list__col--low'>
              {title}
            </div>
          ))}
        </div>
      </div>

      {items.length && (
        <div className='base-list__body'>
          {items.map((item: any, index: number) => (
            <div
              key={index}
              onClick={() => onClickHandler(item.id)}
              className='base-list__row base-list__row--with-hover'
            >
              <div
                className={classNames("base-list__checkbox", {
                  "base-list__checkbox--active": item.active,
                })}
              />
              <div className='base-list__col base-list__col--with-border base-list__col--text-dark'>
                <div className='base-list__address'>{item.address}</div>
              </div>
              <div className='base-list__col base-list__col--with-border base-list__col--text-dark'>
                Пн-Пт {item.description[0]}
                <br />
                Сб, Вс {item.description[1]}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
