import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import "./loader.scss";

interface Props {
  inLine?: boolean;
}

export const Loader: FC<Props> = ({ inLine }): ReactElement => {
  return (
    <div
      className={classNames("base-loader", {
        "base-loader--inline": inLine,
      })}
    >
      <i className='fa fa-circle-o-notch fa-spin fa-3x fa-fw' />
    </div>
  );
};

export default Loader;
