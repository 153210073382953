import {
  GET_DEAL_REQUEST,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  ORDER_PAYMENT_REQUEST,
  ORDER_PAYMENT_SUCCESS,
  ORDER_PAYMENT_ERROR,
  SET_SELECT_DEAL,
  CLEAR_SELECT_DEAL,
  GET_DEAL_DETAILS,
  CLEAR_DEAL_DETAILS,
} from "../actionTypes";

let initialstate = {
  loading: false,
  error: null,
  allDeals: [],
  activeDeals: [],
  closedDeals: [],
  selectDeal: null,
  dealDetails: null,
  total: 0,
  orderPayment: {},
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case GET_DEAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEAL_SUCCESS:
      const deals = [
        ...action.payload.activeDeals,
        ...action.payload.cancelledDeals,
      ];
      return {
        ...state,
        loading: false,
        allDeals: deals,
        activeDeals: action.payload.activeDeals,
        closedDeals: action.payload.cancelledDeals,
        total: action.payload.count,
      };
    case GET_DEAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORDER_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        orderPayment: action.payload,
      };
    case ORDER_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_SELECT_DEAL:
      return {
        ...state,
        selectDeal: action.payload,
      };
    case CLEAR_SELECT_DEAL:
      return {
        ...state,
        selectDeal: null,
      };
    case GET_DEAL_DETAILS:
      return {
        ...state,
        dealDetails: action.payload,
      };
    case CLEAR_DEAL_DETAILS:
      return {
        ...state,
        dealDetails: null,
      };

    default:
      return state;
  }
};
