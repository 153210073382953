import {
  GET_PREMIUM_PRODUCT_REQUEST,
  GET_PREMIUM_PRODUCT_SUCCESS,
  GET_PREMIUM_PRODUCT_ERROR,
  GET_CLEAR_PRODUCTS,
} from "../actionTypes";

let initialstate = {
  loading: false,
  error: null,
  premiumProductsList: [],
  total: 0,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case GET_CLEAR_PRODUCTS:
      return {
        ...state,
        premiumProductsList: [],
      };

    case GET_PREMIUM_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_PREMIUM_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        premiumProductsList: [
          ...state.premiumProductsList,
          ...action.payload.data,
        ], //finalData,
        total: action.payload.count,
      };

    case GET_PREMIUM_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
