import { DRAWER_OPEN, DRAWER_CLOSE } from "../actionTypes";

const initialstate = {
  isOpen: false,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case DRAWER_OPEN:
      document.body.classList.add("scroll-disabled");
      return {
        ...state,
        isOpen: true,
      };
    case DRAWER_CLOSE:
      document.body.classList.remove("scroll-disabled");
      return initialstate;

    default:
      return state;
  }
};
