export enum ERegEx {
  VALID_EMAIL = "valid-email",
  NOT_PLUS_AND_NUMBERS = "not-plus-and-numbers",
  ONLY_SPACES = "only-spaces",
  HTML_TAGS = "html-tags",
  NOT_NUMBERS = "not-numbers",
}

export const regExList: {
  [key in ERegEx]: RegExp;
} = {
  [ERegEx.VALID_EMAIL]:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  [ERegEx.NOT_PLUS_AND_NUMBERS]: /[^+\d]/g,
  [ERegEx.ONLY_SPACES]: /\s/g,
  [ERegEx.HTML_TAGS]: /(<([^>]+)>)/gi,
  [ERegEx.NOT_NUMBERS]: /[^\d]/g,
};
