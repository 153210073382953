import React, { FC, ReactElement, useState } from "react";
import { ProgressNumerical } from "./components/progress-numerical/progress-numerical";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { modalOpen, modalCloseAll } from "../../../store/actions";

import classNames from "classnames";
import "./base-modal.scss";

interface Props {
  type: string;
  wide?: boolean;
  step?: number;
  onClose?: () => void;
}

export const BaseModal: FC<Props> = ({
  type,
  wide,
  step,
  onClose,
  children,
}): ReactElement => {
  const dispatch = useDispatch();

  let { name } = useSelector(
    // @ts-ignore
    (state) => state.modal
  );

  const onCloseHandler = () => {
    dispatch(modalCloseAll());
    if (onClose) onClose();
  };

  return (
    <Modal
      show={type === name}
      onHide={onCloseHandler}
      dialogClassName={classNames(`base-modal base-modal--${type}`, {
        "base-modal--wide": wide,
      })}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="base-modal__icon-close" onClick={onCloseHandler}></div>

      <div className="base-modal__body">
        {step && <ProgressNumerical activeItem={step} />}
        {children}
      </div>
    </Modal>
  );
};
