import React, { FC, ReactElement } from "react";

import { pxToRem } from "../../../helpers/pxToRem";

import "./icon-info.scss";

interface IconInfoProps {
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

export const IconInfo: FC<IconInfoProps> = ({
  size = 18,
  color = "currentColor",
  className,
  onClick,
}): ReactElement => {
  const iconSize = size ? pxToRem(size) : undefined;

  return (
    <div className={`icon-info ${className}`}>
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="8.25" stroke={color} strokeWidth="1.5" />
        <path
          d="M8.38281 12.5322C8.38281 12.9319 8.70679 13.2559 9.10645 13.2559C9.5061 13.2559 9.83008 12.9319 9.83008 12.5322V7.56934C9.83008 7.16968 9.5061 6.8457 9.10645 6.8457C8.70679 6.8457 8.38281 7.16968 8.38281 7.56934V12.5322ZM9.10352 5.80273C9.63672 5.80273 10 5.46289 10 5C10 4.53711 9.63672 4.19727 9.10352 4.19727C8.57617 4.19727 8.20703 4.53711 8.20703 5C8.20703 5.46289 8.57617 5.80273 9.10352 5.80273Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
