import React, { FC, ReactElement, useEffect, useState } from "react";
import moment from "moment";

import "./base-timer.scss";

interface Props {
  initTime?: number;
  onFinish: () => void;
}

export const BaseTimer: FC<Props> = ({
  initTime = 60,
  onFinish,
}): ReactElement => {
  const [seconds, setSeconds] = useState<number>(initTime);

  const transformToClock = (time: number) => {
    if (time) {
      return moment(time * 1000).format("m:ss");
    } else {
      return "0:00";
    }
  };

  useEffect(() => {
    let interval: any = null;
    if (seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      onFinish();
    }

    return () => clearInterval(interval);
  }, [seconds, onFinish]);

  return (
    <div className='base-modal__text base-modal__text--without-bottom-offset'>
      Получить новый код можно через {transformToClock(seconds)}
    </div>
  );
};
