import React from 'react';
import { Alert } from "react-bootstrap";
import mobLogo from "../../images/posh-mob-logo.png";
import './appDownload.scss';
const AppDownload = () => {
    return (
        <Alert
            variant="light"
            show={true}
            dismissible={false}
            className="app-alert"
        >
            <div className="app-dialog">
                <img src={mobLogo} alt="mb-logo" />
                <div className="app-title">
                    <div>Установите мобильное приложение</div>
                    <div>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </div>
                <a
                    className="btn-store ml-auto"
                    href="https://posh-market.onelink.me/J8mW/b90b0697"
                >
                    Скачать
            </a>
            </div>
        </Alert>
    )
}
export default AppDownload;