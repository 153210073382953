export const calcStatusTitle = (
  status: number,
  isAuthentication: boolean
): string => {
  if (!isAuthentication) {
    if (status === 1) {
      return "Подтверждение наличия";
    }
    if (status === 3 || status === 27) {
      return "Наличие товара подтверждено";
    }
    if (status === 4) {
      return "Товар оплачен";
    }
    if (status === 5 || status === 26) {
      return "Курьер заказан";
    }
    if (status === 23) {
      return "Товар передан курьеру";
    }
    if (status === 9) {
      return "Товар доставлен";
    }
    if (status === 10) {
      return "Оценка сделки";
    }
    if (status === 12) {
      return "Сделка закрыта";
    }
  }

  if (isAuthentication) {
    if (status === 1) {
      return "Подтверждение наличия";
    }
    if (status === 3) {
      return "Наличие товара подтверждено";
    }
    if (status === 4) {
      return "Товар оплачен";
    }
    if (status === 5 || status === 26) {
      return "Курьер заказан";
    }
    if (status === 23 || status === 25) {
      return "Товар передан курьеру";
    }
    if (status === 7) {
      return "Проверка подлинности";
    }
    if (status === 8) {
      return "Проверка пройдена";
    }
    if (status === 9) {
      return "Товар доставлен";
    }
    if (status === 10) {
      return "Оценка сделки";
    }
    if (status === 12) {
      return "Сделка закрыта";
    }
  }

  return "Сделка отменена";
};
