import axios from "axios";
import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  SET_PROFILE_REQUEST,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_FAILURE,
  SET_DELIVERY_ADDRESS,
  CLEAR_PROFILE,
} from "../actionTypes";

import { BASE_API_URL, NEW_API_URL } from "../../global/ApiConfig";

import { getAddress } from "../../api/get-address";
import { postAddress } from "../../api/post-address";

import { setHttpConfig } from "../../shared/helpers/setHttpConfig";

export const getProfile = (callback) => {
  return async (dispatch) => {
    dispatch({ type: GET_PROFILE_REQUEST });

    function onSuccess(success) {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: success.data.data,
      });
      console.log("данные пользователя ", success);
      callback(success);
      return success;
    }
    function onError(error) {
      dispatch({
        type: GET_PROFILE_FAILURE,
        payload: error,
      });
      // console.log(error);
      return error;
    }

    try {
      const success = await axios.get(
        `${NEW_API_URL}/user/details`,
        setHttpConfig(localStorage.getItem("_token"))
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};

export const clearProfile = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PROFILE,
    });
  };
};
