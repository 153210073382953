import axios from "axios";
import { NEW_API_URL, PAGINATE_COUNT } from "../global/ApiConfig";
import { setHttpConfig } from "../shared/helpers/setHttpConfig";

import { ICityPayload } from "./../shared/models/city";

export const getAllCities = async (payload: ICityPayload): Promise<any> => {
  // if(!localStorage.getItem("_token")) return
  if (!payload.pageNum) return;

  const count = payload.pageNum * PAGINATE_COUNT;

  const data = await axios.get(
    `${NEW_API_URL}/cities?offset=0&limit=${count}`,
    setHttpConfig(localStorage.getItem("_token"))
  );

  return data.data.data;
};
