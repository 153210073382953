const rootElem: HTMLHtmlElement | null = window
  ? document.querySelector("html")
  : null;

const remSize: string = rootElem ? getComputedStyle(rootElem).fontSize : "16px";

const coefficient = parseFloat(remSize);

export const pxToRem = (px: number | string): string => {
  if (typeof px === "string") {
    return `${parseFloat(px) / coefficient}rem`;
  }

  return `${px / coefficient}rem`;
};
