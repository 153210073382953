import axios from "axios";
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESEND_OTP_REQUEST,
  // RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  // GET_USER_REQUEST,
  // GET_USER_SUCCESS,
  // GET_USER_FAILURE,
} from "../actionTypes";

import { NEW_API_URL } from "../../global/ApiConfig";
import { success, fail } from "../../global/Toast";

import { setHttpConfig } from "../../shared/helpers/setHttpConfig";

/* Register User */
export const register = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: REGISTER_REQUEST });
      axios
        // .post(`${BASE_API_URL}RegisterUserUpdated`, data)
        .post(`${NEW_API_URL}/login/register`, data, setHttpConfig())
        .then((res) => {
          success("Register successfully");
          console.log("REGISTER >>> ", res);

          dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data.data[0],
          });
          resolve(res);
        })
        .catch((err) => {
          fail("Oops! Error While Registrtion");
          dispatch({
            type: REGISTER_FAILURE,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

/** Login **/
export const login = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: LOGIN_REQUEST });
      axios
        // .post(`${BASE_API_URL}Login`, data)
        .post(`${NEW_API_URL}/login/register`, data, setHttpConfig())
        .then((res) => {
          console.log("LOGIN >>> ", res);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: LOGIN_FAILURE,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

/** Assuming that will get same reponse for this api like login API  */
export const ResendVerificationCode = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: RESEND_OTP_REQUEST });
      axios
        .post(`${NEW_API_URL}/login/verify-code`, data, setHttpConfig())
        // .post(`${BASE_API_URL}ResendVerificationCode`, data)
        .then((res) => {
          console.log("VERIFY CODE ", res);
          if (!!res) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: res.data.data,
            });
          }
          console.log("данные пользователя установлены");
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: RESEND_OTP_FAILURE,
            payload: err,
          });
          reject(err);
        });
    });
  };
};
