var jwt = require("jsonwebtoken");

const encrypt = (obj) => {
  var token = jwt.sign(obj, "dm");
  localStorage.setItem("token", token);
};

const decrypt = (token) => {
  var decoded = jwt.verify(token, "dm");
  return decoded;
};

const addNewKey = (data) => {
  var obj = {};
  let existingToken = localStorage.getItem("token");
  if (existingToken) {
    let d = decrypt(existingToken);
    var obj = { ...d };
    obj[`${data.key}`] = `${data.val}`;
    encrypt(obj);
  } else {
    obj[`${data.key}`] = `${data.val}`;
    encrypt(obj);
  }
};

export { encrypt, decrypt, addNewKey };
