import { MODAL_OPEN, MODAL_CLOSE } from "../actionTypes";

let initialstate = {
  name: "undefined",
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        name: action.payload,
      };
    case MODAL_CLOSE:
      return initialstate;

    default:
      return state;
  }
};
