import React, { Suspense, useEffect, useState, useCallback } from "react";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import classNames from "classnames";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-pagination/assets/index.css";

import "react-multi-carousel/lib/styles.css";

import "./shared/scss/modules/input.scss";

import { PublicRoute, PrivateRoute } from "./global/PrivateRoute";
import Loader from "./components/Loader";
import $ from "jquery";

import { BaseDrawer } from "./shared/components/base-drawer/base-drawer";

import { postProductsReceivedByBuyer } from "./api/post-products-received-by-buyer";
import { postRateDeal } from "./api/post-rate-deal";

import {
  BaseModal,
  BaseButton,
  BaseError,
  BaseTimer,
  OrderItem,
  BaseRating,
} from "./shared/components";

import {
  BaseInputText,
  BaseInputNumber,
  BaseInputEmail,
  BaseInputPhone,
  BaseSelect,
  BaseTextArea,
} from "./shared/components/form-fields";

import {
  drawerClose,
  getProfile,
  getAllDeals,
  getUserDetail,
  setDeliveryAddress,
  login,
  modalOpen,
  modalCloseAll,
  getDealDetails,
} from "./store/actions";

import { decrypt, addNewKey } from "./global/JWT";

import { postSmsCode } from "./api/post-sms-code";
import { getCitiesByQuery } from "./api/get-cities-by-query";
import { getAllCities } from "./api/get-all-cities";
import { postProfile } from "./api/post-profile";
import { getNickname } from "./api/get-nickname";

import { IBaseError } from "./shared/models/error";

/** Lazy Routes */
const Home = React.lazy(() => import("./components/Home"));
const Login = React.lazy(() => import("./Forms/Login"));
const Register = React.lazy(() => import("./components/Register"));
const ProductList = React.lazy(() =>
  import("./components/Product/ProductList")
);
const Deals = React.lazy(() => import("./pages/Deals/Deals"));
const OrderPayment = React.lazy(() => import("./components/MyOrders/payment"));
const ProductDetails = React.lazy(() =>
  import("./components/Product/ProductDetails")
);
// const Checkout = React.lazy(() => import("./components/Checkout"));
const Checkout = React.lazy(() => import("./pages/Checkout/Checkout"));
const PaymentSuccess = React.lazy(() =>
  import("./pages/Payment/PaymentSuccess/paymentSuccess")
);

const OrderStatus = React.lazy(() =>
  import("./components/MyOrders/orderStatus")
);
const Blog = React.lazy(() => import("./components/Blog/BlogList"));
const BlogDetail = React.lazy(() => import("./components/Blog/BlogDetail"));
const PageNotFound = React.lazy(() => import("./components/PageNotFound"));
const Brands = React.lazy(() => import("./components/Brands"));
const About = React.lazy(() => import("./components/About"));
const Profile = React.lazy(() => import("./pages/Profile/profile"));

// const UiKit = React.lazy(() => import("./pages/UI-Kit/ui-kit"));
const OrderSingle = React.lazy(() =>
  import("./pages/OrderSingle/order-single")
);

const userInitial = {
  nickname: {
    name: "nickname",
    value: "",
    error: false,
    errorText: "Недопустимое значение никнейма",
  },
  name: {
    name: "name",
    value: "",
    error: false,
    errorText: "Имя не установлено",
  },
  email: {
    name: "email",
    value: "",
    error: false,
    errorText: "Email не установлен",
  },
  phone: {
    name: "phone",
    value: "",
    error: false,
    errorText: "Неправильный формат телефона",
  },
  code: {
    name: "code",
    value: "",
    error: false,
    errorText: "Неправильный код",
  },
  city: {
    name: "city",
    value: {
      label: "",
      value: "",
    },
    error: false,
    errorText: "Город не установлен",
  },
  auth: false,
  availabilityRequestStep: 1,
};

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  let drawerIsOpen = useSelector((state) => state.drawer.isOpen);
  let profile = useSelector((state) => state.profile.data);
  let selectDeal = useSelector((state) => state.deal.selectDeal);
  let dealDetails = useSelector((state) => state.deal.dealDetails);

  const [user, setUser] = useState(userInitial);
  const [timerValue, setTimerValue] = useState(60);
  const [timerVisible, setTimerVisible] = useState(true);
  // const [isNicknameAvailability, setIsNicknameAvailability] = useState({
  //   error: false,
  //   text: "",
  // });
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState([]);

  const [cityPaginationPage, setCityPaginationPage] = useState(1);

  const [selectConfig, setSelectConfig] = useState({
    pageNum: 1,
    query: "",
  });

  const [error, setError] = useState(false);

  const [review, setReview] = useState({
    rating: -1,
    comment: "",
  });

  // dispatch(modalOpen("auth-bio"));

  const onFinishTimer = () => {
    setTimerVisible(false);
  };

  const backToAuthStep = () => {
    dispatch(modalOpen("auth-phone"));
  };

  const onClearFormsHandler = () => {
    setUser(userInitial);
  };

  const onSubmitCodeHandler = (code) => {
    setLoading(true);

    postSmsCode({
      contact_number: user.phone.value.replace(/\D/g, "").substr(1),
      country_prefix: "7",
      verification_code: code,
    }).then((data) => {
      setLoading(false);

      setUser({
        ...user,
        code: {
          ...user.phone,
          value: "",
          error: !data,
          errorText: "Неверный код",
        },
      });

      if (!data) return;

      addNewKey({ key: "dump", val: data.api_token });
      localStorage.setItem("_token", data.api_token);
      window.dispatchEvent(new Event("storage"));

      if (data.username) {
        dispatch(modalCloseAll());
        setUser(userInitial);
      } else {
        dispatch(modalOpen("auth-bio"));
      }
    });
  };

  const onSubmitPhoneHandler = (event) => {
    event.preventDefault();

    const isValidateSuccess =
      user.phone.value.replace(/\D/g, "").substr(1).length === 10;

    setUser({
      ...user,
      phone: {
        ...user.phone,
        error: !isValidateSuccess,
        errorText: "Неверный формат телефона",
      },
      code: {
        ...user.code,
        value: "",
      },
    });

    if (!isValidateSuccess) return;

    setLoading({
      loading: true,
    });

    const data = {
      contact_number: user.phone.value.replace(/\D/g, "").substr(1),
      country_prefix: "7",
      device_token: "eee",
      device_type: "3",
    };

    dispatch(login(data)).then((resp) => {
      setLoading(false);
      dispatch(modalOpen("auth-code"));
      // this.startTimer();
    });
  };

  const isNicknameExist = async (data) => {
    return await getNickname({
      nick_name: data.value,
      build: 0,
      version: 0,
    }).then((response) => {
      return response.status === "1";
    });
  };

  const onChangeInputHandler = async (data) => {
    setUser({
      ...user,
      [data.name]: data,
    });

    if (data.name === "code" && String(data.value).length === 4) {
      onSubmitCodeHandler(String(data.value));
    }
  };

  const onChangeInputHandlerNickName = async (data) => {
    setUser({
      ...user,
      nickname: {
        ...user.nickname,
        ...data,
      },
    });

    const isExist = await isNicknameExist(user.nickname);

    setUser({
      ...user,
      nickname: {
        ...user.nickname,
        ...data,
        error: isExist,
        errorText: "Никнейм установлен неверно",
      },
    });
  };

  const resendCode = () => {
    setTimerValue(60);
    setTimerVisible(true);

    const data = {
      contact_number: user.phone.value.replace(/\D/g, "").substr(1),
      country_prefix: "7",
      device_token: "eee",
      device_type: "3",
    };

    dispatch(login(data)).then((resp) => {
      // console.log("Повторно отправили код в смс");
    });
  };

  const onSubmitRegisterHandler = async (event) => {
    event.preventDefault();

    setLoading(true);

    const isExist = await isNicknameExist(user.nickname);

    setUser({
      ...user,
      nickname: {
        ...user.nickname,
        error: isExist,
        errorText: "Никнейм установлен неверно",
      },
      name: {
        ...user.name,
        error: !user.name.value,
        errorText: "Имя не установлено",
      },
      email: {
        ...user.email,
        error: !user.email.value,
        errorText: "Email не установлен",
      },
      city: {
        ...user.city,
        error: !user.city.value.value,
        errorText: "Город не установлен",
      },
    });

    setTimeout(function () {
      const isReadyForRequest =
        !user.nickname.error &&
        !user.name.error &&
        !user.email.error &&
        !user.city.error;

      if (!isReadyForRequest) return;

      postProfile({
        user_id: profile.user_id,
        first_name: user.name.value,
        last_name: user.name.value,
        nick_name: user.nickname.value,
        device_token: "eee",
        email: user.email.value,
        city_id: Number(user.city.value.value),
      })
        .then(() => {
          dispatch(getProfile()).then(() => {
            // dispatch(modalOpen("availability-request"));
            dispatch(modalCloseAll());
            setLoading(false);
            ReactPixel.track("CompleteRegistration");
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    }, 1000);
  };

  const onHandlerSelect = (e) => {
    setUser({
      ...user,
      city: {
        ...user.city,
        value: e,
        error: false,
      },
    });
  };

  const onChangeHandlerSelect = (e) => {
    setSelectConfig({
      query: e,
      pageNum: 1,
    });
  };

  const uploadCities = () => {
    setSelectConfig({
      ...selectConfig,
      pageNum: selectConfig.pageNum + 1,
    });
  };

  useEffect(() => {
    if (!selectConfig.query) {
      getAllCities({ pageNum: selectConfig.pageNum }).then((items) => {
        setCities(
          items.map((item) => {
            return {
              label: item.FullName,
              value: String(item.ID),
            };
          })
        );
      });
    } else {
      getCitiesByQuery({
        pageNum: selectConfig.pageNum,
        query: selectConfig.query,
      }).then((items) => {
        setCities(
          items.map((item) => {
            return {
              label: item.FullName,
              value: String(item.ID),
            };
          })
        );
      });
    }
  }, [selectConfig]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      const token = localStorage.getItem("_token");

      if (!!token) {
        dispatch(getProfile());
      }
    });

    window.dispatchEvent(new Event("storage"));
  }, [dispatch]);

  useEffect(() => {
    if (!profile) return;

    dispatch(
      getAllDeals({
        user_id: profile.user_id,
      })
    );
  }, [dispatch, profile]);

  const documentClickHandler = useCallback(
    (e) => {
      const target = e.target;

      const clickOutside =
        !target.closest(".base-drawer") &&
        !target.closest(".base-tabs") &&
        drawerIsOpen;
      const clickOnCloseBtn = target.closest(".base-drawer__btn-close");

      if (clickOutside || clickOnCloseBtn) {
        dispatch(drawerClose());
      }
    },
    [dispatch, drawerIsOpen]
  );

  useEffect(() => {
    document.addEventListener("click", documentClickHandler);
    return () => {
      document.removeEventListener("click", documentClickHandler);
    };
  }, [documentClickHandler]);

  useEffect(() => {
    var btn = $("#bottom-button");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    /** Redirect app to play store if its open from mobile browser */
    /*
    var isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    if (isMobile) {
      window.open('https://posh-market.onelink.me/J8mW/b90b0697')
      // window.location = 'https://posh-market.onelink.me/J8mW/b90b0697';
    }
    */
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      window.open("https://posh-market.onelink.me/J8mW/b90b0697");
    }
  }, []);

  const updateDealData = () => {
    if (history.location.pathname === "/orders") {
      dispatch(
        getAllDeals({
          user_id: profile.user_id,
        })
      );
    } else {
      dispatch(
        getDealDetails({
          id: dealDetails.deal_id,
          user_id: profile.user_id,
        })
      );
    }
  };

  const postReview = () => {
    setLoading(true);
    postProductsReceivedByBuyer({ deal_id: selectDeal.deal_id }).then(
      (response) => {
        setLoading(false);
        if (response.data.status === "0") {
          updateDealData();
          dispatch(modalOpen("rate-deal"));
        } else {
          setError(true);
        }
      }
    );
  };

  const rateDeal = () => {
    if (!(review.rating > -1)) {
      setError(true);
      return;
    }

    setLoading(true);

    postRateDeal({
      deal_id: selectDeal.deal_id,
      rating_by: "buyer",
      ratings: review.rating,
      comment: window.btoa(unescape(encodeURIComponent(review.comment))),
    }).then((response) => {
      setLoading(false);
      if (response.data.status === "0") {
        updateDealData();
        dispatch(modalOpen("rate-deal-success"));
      } else {
        setError(true);
      }
    });
  };

  const setRating = (rating) => {
    setError(false);
    setReview({
      ...review,
      rating: ++rating,
    });
  };

  const onChangeTextareaHandler = (text) => {
    setError(false);
    setReview({
      ...review,
      comment: text.value,
    });
  };

  const clearData = () => {
    setError(false);
    setReview({
      rating: -1,
      comment: "",
    });
  };

  const goToCheckout = () => {
    dispatch(modalCloseAll());
    history.push("/orders?current");
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <BaseDrawer isOpen={drawerIsOpen} />
        <div
          className={classNames({
            "app-shadow": drawerIsOpen,
          })}
        ></div>
        <Switch>
          {/* <PublicRoute exact path="/" component={ProductList} /> */}
          <PublicRoute
            exact
            path='/'
            component={Home}
            showHeader={true}
            showFooter={true}
          />
          {/* <PublicRoute path="/products" component={ProductList} /> */}
          <PublicRoute
            path='/women/:filter?/:b_name?/:b_id?'
            component={ProductList}
            showHeader={true}
            showFooter={true}
          />
          <PublicRoute
            path='/men'
            component={ProductList}
            showHeader={true}
            showFooter={true}
          />
          <PublicRoute
            path='/register'
            component={Register}
            showHeader={false}
            showFooter={true}
          />
          <PublicRoute
            path='/login'
            component={Login}
            showHeader={false}
            showFooter={true}
          />
          <PublicRoute
            path='/:main_cat/:sub_cat/:brand/:color'
            component={ProductDetails}
            showHeader={true}
            showFooter={true}
          />
          <PublicRoute
            exact
            path='/brands'
            component={Brands}
            showHeader={true}
            showFooter={true}
          />
          <PublicRoute
            exact
            path='/about'
            component={About}
            showHeader={true}
            showFooter={true}
          />
          <PublicRoute
            exact
            path='/blog'
            component={Blog}
            showHeader={true}
            showFooter={true}
          />
          <PublicRoute
            path='/blog/:id'
            component={BlogDetail}
            showHeader={true}
          />
          <PublicRoute
            path='/checkout/:id'
            component={Checkout}
            showHeader={true}
            showFooter={true}
          />
          <PrivateRoute
            path='/app_payment_success'
            component={PaymentSuccess}
            showHeader={true}
            showFooter={true}
          />
          <PrivateRoute
            exact
            path='/orders'
            component={Deals}
            showHeader
            showFooter
          />
          <PrivateRoute
            path='/order/:id'
            component={OrderSingle}
            showHeader
            showFooter
          />
          <PrivateRoute
            exact
            path='/order/payment'
            component={OrderPayment}
            showHeader={true}
            showFooter={true}
          />
          <PrivateRoute
            path='/order/payment/status'
            component={OrderStatus}
            showHeader={true}
            showFooter={true}
          />
          <PrivateRoute
            exact
            path='/profile'
            component={Profile}
            showHeader={true}
            showFooter={true}
          />
          {/* <PublicRoute path='/ui-kit' component={UiKit} showHeader={false} /> */}
          <PublicRoute path='/404' component={PageNotFound} showHeader={true} />
          <PublicRoute component={PageNotFound} showHeader={true} />
        </Switch>
        {/* </div> */}
      </Suspense>

      <ToastContainer />

      <BaseModal
        type='auth-phone'
        step={user.availabilityRequestStep}
        onClose={onClearFormsHandler}
      >
        <div className='base-modal__title'>
          Войдите или зарегистрируйтесь,чтобы продолжить
        </div>
        <form
          onSubmit={onSubmitPhoneHandler}
          className='base-modal__content base-modal__content--offset-right'
        >
          <div className='base-modal__input'>
            <BaseInputPhone
              name='phone'
              header={"Телефон"}
              value={user.phone.value}
              onChange={onChangeInputHandler}
              placeholder={"Введите ваше телефон"}
              footer={"Нужен для входа по коду из СМС. Это бесплатно"}
              error={user.phone.error}
              slot={
                user.phone.error && (
                  <BaseError
                    type={IBaseError.DANGER}
                    text={user.phone.errorText}
                  />
                )
              }
            />
          </div>
          {loading && <Loader inLine />}
          {!loading && (
            <BaseButton submit primary>
              Получить код
            </BaseButton>
          )}
        </form>
      </BaseModal>

      <BaseModal
        type='auth-code'
        step={user.availabilityRequestStep}
        onClose={onClearFormsHandler}
      >
        <div className='base-modal__title'>Введите код</div>
        <form
          onSubmit={onSubmitCodeHandler}
          className='base-modal__content base-modal__content--offset-right'
        >
          <div className='base-modal__text'>
            Мы отправили код подтверждения на номер
            <br /> +{user.phone.value} &nbsp;
            <div className='base-modal__link' onClick={backToAuthStep}>
              Изменить
            </div>
          </div>

          <BaseInputNumber
            isCode
            name='code'
            header={"Код из смс"}
            value={user.code.value}
            className={"input--no-bottom-offset"}
            error={user.code.error}
            slot={
              <>
                {loading ? (
                  <Loader inLine />
                ) : (
                  <>
                    {user.code.error && (
                      <BaseError
                        type={IBaseError.DANGER}
                        text={user.code.errorText}
                      />
                    )}
                    {timerVisible ? (
                      <BaseTimer
                        initTime={timerValue}
                        onFinish={onFinishTimer}
                      />
                    ) : (
                      <div className='base-modal__link' onClick={resendCode}>
                        Получить новый код
                      </div>
                    )}
                  </>
                )}
              </>
            }
            onChange={onChangeInputHandler}
          />
        </form>
      </BaseModal>

      <BaseModal
        type='auth-bio'
        step={user.availabilityRequestStep}
        onClose={onClearFormsHandler}
      >
        <div className='base-modal__title'>Заполните ваши данные</div>
        <form
          onSubmit={onSubmitRegisterHandler}
          className='base-modal__content base-modal__content--offset-right'
        >
          <div className='base-modal__text'>
            * — обязательное для заполнения
          </div>
          <div className='base-modal__input'>
            <BaseInputText
              name='nickname'
              value={user.nickname.value}
              placeholder={"Введите ваш никнейм"}
              header={"Никнейм*"}
              onChange={onChangeInputHandlerNickName}
              error={user.nickname.error}
              slot={
                user.nickname.error && (
                  <BaseError
                    type={IBaseError.DANGER}
                    text={user.nickname.errorText}
                  />
                )
              }
            />
          </div>
          <div className='base-modal__input'>
            <BaseInputText
              name='name'
              value={user.name.value}
              placeholder={"Введите ваше имя"}
              header={"Имя*"}
              footer={"Будем использовать для личного кабинета"}
              onChange={onChangeInputHandler}
              error={user.name.error}
              slot={
                user.name.error && (
                  <BaseError
                    type={IBaseError.DANGER}
                    text={user.name.errorText}
                  />
                )
              }
            />
          </div>
          <div className='base-modal__input'>
            <BaseInputEmail
              name='email'
              value={user.email.value}
              placeholder={"Введите ваш email"}
              header={"Email*"}
              footer={"На этот адрес будем отправлять подтверждения покупок"}
              onChange={onChangeInputHandler}
              error={user.email.error}
              slot={
                user.email.error && (
                  <BaseError
                    type={IBaseError.DANGER}
                    text={user.email.errorText}
                  />
                )
              }
            />
          </div>
          <div className='base-modal__input'>
            <BaseSelect
              defaultValue={[]}
              items={cities}
              header='Город'
              placeholder='Поиск города'
              loadingMessage='Идёт поиск города ...'
              className='checkout-steps__input'
              onChange={onHandlerSelect}
              onInputChange={onChangeHandlerSelect}
              onScrollBottom={uploadCities}
              slot={
                user.city.error && (
                  <BaseError
                    type={IBaseError.DANGER}
                    text={user.city.errorText}
                  />
                )
              }
            />
          </div>
          {loading && <Loader inLine />}
          {!loading && (
            <BaseButton submit primary>
              Зарегистрироваться
            </BaseButton>
          )}
        </form>
      </BaseModal>

      {/* <BaseModal wide type='auth-error' onClose={this.onClearFormsHandler}>
        <div className='base-modal__content'>
          <div className='base-modal__row base-modal__row--with-offset base-modal__row--v-center'>
            <div className='base-modal__image base-modal__image--something-went-wrong' />
            <div className='base-modal__info'>
              <div className='base-modal__info-title'>
                Ого, что-то пошло не так
              </div>
              <div className='base-modal__info-text'>
                Мы уже зафиксировали проблему, а пока перезагрузите страницу и
                попробуйте снова
              </div>
            </div>
          </div>
        </div>
      </BaseModal> */}

      <BaseModal type='pickup-point-error'>Пункт выдачи не указан</BaseModal>
      <BaseModal type='delivery-address'>Адрес доставки не указан</BaseModal>
      <BaseModal type='delete-deal-success'>Сделка успешно отменена</BaseModal>
      <BaseModal type='delete-deal-error'>Сделка не удалена</BaseModal>

      {/* <BaseModal type='deal-create-error'>
        У Вас есть активная сделка с этим товаром. Проверьте раздел "Cделки"
      </BaseModal> */}

      {/* <BaseModal wide type='deal-create-error'>
        <div className='base-modal__content'>
          <div className='base-modal__row base-modal__row--with-offset base-modal__row--v-center'>
            <div className='base-modal__info'>
              <div className='base-modal__info-text'>
                У Вас есть активная сделка с этим товаром. Проверьте раздел
                "Cделки"
              </div>
            </div>
          </div>
          <div className='base-modal__row base-modal__row--h-center'>
            <BaseButton submit primary onClick={goToCheckout}>
              Перейти в сделки
            </BaseButton>
          </div>
        </div>
      </BaseModal> */}

      {/* <BaseModal wide type='availability-success' onClose={onClearFormsHandler}>
        <div className='base-modal__content'>
          <div className='base-modal__row base-modal__row--with-offset base-modal__row--v-center'>
            <div className='base-modal__image base-modal__image--know-availability' />
            <div className='base-modal__info'>
              <div className='base-modal__info-title'>Уже узнаем наличие!</div>
              <div className='base-modal__info-text'>
                Мы пришлем вам смс, когда продавец подтвердит наличие товара.
                <div className='base-modal__info-delimiter' />
                Вы сможете оплатить товар после подтверждения.
              </div>
            </div>
          </div>
          <div className='base-modal__row base-modal__row--h-center'>
            <BaseButton submit primary onClick={goToCheckout}>
              Перейти в сделки
            </BaseButton>
          </div>
        </div>
      </BaseModal> */}

      <BaseModal type='good-received'>
        <div className='base-modal__content'>
          <div className='base-modal__title'>Вы получили товар?</div>
          {selectDeal && <OrderItem isCompact inModal order={selectDeal} />}
        </div>
        {loading && <Loader inLine />}
        {!loading && !error && (
          <div className='base-modal__footer base-modal__footer--align-center'>
            <BaseButton primary onClick={postReview}>
              ТОВАР ПОЛУЧЕН
            </BaseButton>
          </div>
        )}
        {!loading && error && (
          <BaseError
            type={IBaseError.DANGER}
            icon={IBaseError.DANGER}
            text='Произошла ошибка при обновлении статуса сделки. Перезагрузите страницу и попробуйте ещё раз.'
          />
        )}
      </BaseModal>

      <BaseModal type='rate-deal' onClose={clearData}>
        <div className='base-modal__content'>
          <div className='base-modal__title'>Оцените товар</div>
          <BaseRating
            className='base-rating--in-modal'
            selectRating={setRating}
          />
          <BaseTextArea
            value={review.comment}
            placeholder={"Напишите ваш комментарий"}
            header={"Комментарий"}
            onChange={onChangeTextareaHandler}
            className='order-single__textarea'
            slot={
              <>
                {error && (
                  <BaseError
                    type={IBaseError.DANGER}
                    icon={IBaseError.DANGER}
                    text='Рейтинг не установлен'
                  />
                )}
              </>
            }
          />
        </div>
        {loading && <Loader inLine />}
        {!loading && (
          <div className='base-modal__footer base-modal__footer--align-center'>
            <BaseButton primary onClick={rateDeal}>
              ТОВАР ПОЛУЧЕН
            </BaseButton>
          </div>
        )}
      </BaseModal>

      <BaseModal wide type='rate-deal-success' onClose={clearData}>
        <div className='base-modal__content'>
          <div className='base-modal__row base-modal__row--with-offset base-modal__row--v-center'>
            <div className='base-modal__image base-modal__image--know-availability' />
            <div className='base-modal__info'>
              <div className='base-modal__info-title'>Спасибо за оценку</div>
              <div className='base-modal__info-text'>
                Если хотите помочь нам стать лучше — напишите нам на
                {/* Если хотите помочь нам стать лучше — напишите в чат поддержки */}
                {/* или на почту */}
              </div>
              <div className='base-modal__info-text base-modal__info-text--email'>
                {/* app_support@posh-market.ru */}
                support@posh-market.ru
              </div>
            </div>
          </div>
          <div className='base-modal__row base-modal__row--h-center'>
            <BaseButton submit primary onClick={goToCheckout}>
              Перейти в сделки
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </>
  );
}

export default App;
