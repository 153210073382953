import { DRAWER_OPEN, DRAWER_CLOSE } from "../actionTypes";

export const drawerOpen = () => {
  return (dispatch) => {
    dispatch({
      type: DRAWER_OPEN,
    });
  };
};

export const drawerClose = () => {
  return (dispatch) => {
    dispatch({
      type: DRAWER_CLOSE,
    });
  };
};
