import axios from "axios";
import {
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_ERROR,
  GET_SINGLE_BLOG_REQUEST,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_ERROR,
  GET_RECENT_POST_REQUEST,
  GET_RECENT_POST_SUCCESS,
  GET_RECENT_POST_ERROR,
  GET_POPULAR_POST_REQUEST,
  GET_POPULAR_POST_SUCCESS,
  GET_POPULAR_POST_ERROR,
  GET_HOME_PAGE_BLOGS_REQUEST,
  GET_HOME_PAGE_BLOGS_SUCCESS,
  GET_HOME_PAGE_BLOGS_ERROR,
} from "../actionTypes";
import _get from "lodash/get";

const BLOG_API_URL = "https://old.posh-market.ru/wp-json/wp/v2/posts";

/**
 ?per_page=10
*/

/** Get Blogs */
export const getHomePageBlogList = (page = 1, per_page = 3) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: GET_HOME_PAGE_BLOGS_REQUEST });
      axios
        .get(`${BLOG_API_URL}?_embed&per_page=${per_page}&page=${page}`)
        .then((res) => {
          let totalRecords = _get(res, "headers.x-wp-total", 0);
          let totalPages = _get(res, "headers.x-wp-totalpages", 0);
          dispatch({
            type: GET_HOME_PAGE_BLOGS_SUCCESS,
            payload: { data: res.data, totalPages, totalRecords },
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: GET_HOME_PAGE_BLOGS_ERROR,
            payload: err,
          });
          reject(err);
        });
    });
  };
};
export const getBlogList = (page, per_page) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: GET_BLOGS_REQUEST });
      axios
        .get(
          `${BLOG_API_URL}?_embed&per_page=${per_page}&page=${page}&offset=${page * per_page
          }`
        )
        .then((res) => {
          let totalRecords = _get(res, "headers.x-wp-total", 0);
          let totalPages = _get(res, "headers.x-wp-totalpages", 0);
          dispatch({
            type: GET_BLOGS_SUCCESS,
            payload: { data: res.data, totalPages, totalRecords },
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: GET_BLOGS_ERROR,
            payload: err,
          });
          reject(err);
        });
    });
  };
};

export const getBlogDetails = (blogId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_SINGLE_BLOG_REQUEST });
    axios
      .get(`${BLOG_API_URL}/${blogId}?_embed`)
      .then((res) => {
        dispatch({
          type: GET_SINGLE_BLOG_SUCCESS,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_SINGLE_BLOG_ERROR,
          payload: err,
        });
        reject(err);
      });
  });
};

export const getRecentBlogs = (page = 1, per_page = 2) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_RECENT_POST_REQUEST });
    axios
      .get(
        `${BLOG_API_URL}/?_embed&per_page=${per_page}&page=${page}&order=desc`
      )
      .then((res) => {
        dispatch({
          type: GET_RECENT_POST_SUCCESS,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_RECENT_POST_ERROR,
          payload: err,
        });
        reject(err);
      });
  });
};

export const getPolularBlogs = (page = 1, per_page = 5) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_POPULAR_POST_REQUEST });
    axios
      .get(
        `${BLOG_API_URL}/?_embed&per_page=${per_page}&page=${page}&order=desc`
      )
      .then((res) => {
        dispatch({
          type: GET_POPULAR_POST_SUCCESS,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_POPULAR_POST_ERROR,
          payload: err,
        });
        reject(err);
      });
  });
};
