export const CREATE_DEAL_REQUEST = "CREATE_DEAL_REQUEST";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_ERROR = "CREATE_DEAL_ERROR";

export const GET_DEAL_DETAILS = "GET_DEAL_DETAILS";
export const CLEAR_DEAL_DETAILS = "CLEAR_DEAL_DETAILS";

export const GET_DEAL_REQUEST = "GET_DEAL_REQUEST";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";
export const GET_DEAL_ERROR = "GET_DEAL_ERROR";

export const ORDER_PAYMENT_REQUEST = "ORDER_PAYMENT_REQUEST";
export const ORDER_PAYMENT_SUCCESS = "ORDER_PAYMENT_SUCCESS";
export const ORDER_PAYMENT_ERROR = "ORDER_PAYMENT_ERROR";

export const SET_SELECT_DEAL = "SET_SELECT_DEAL";
export const CLEAR_SELECT_DEAL = "CLEAR_SELECT_DEAL";
