export const GET_HOME_PAGE_BLOGS_REQUEST = "GET_HOME_PAGE_BLOGS_REQUEST";
export const GET_HOME_PAGE_BLOGS_SUCCESS = "GET_HOME_PAGE_BLOGS_SUCCESS";
export const GET_HOME_PAGE_BLOGS_ERROR = "GET_HOME_PAGE_BLOGS_ERROR";

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_ERROR = "GET_BLOGS_ERROR";

export const GET_SINGLE_BLOG_REQUEST = "GET_SINGLE_BLOG_REQUEST";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_ERROR = " GET_SINGLE_BLOG_ERROR";

export const GET_RECENT_POST_REQUEST = "GET_RECENT_POST_REQUEST";
export const GET_RECENT_POST_SUCCESS = "GET_RECENT_POST_SUCCESS";
export const GET_RECENT_POST_ERROR = "GET_RECENT_POST_ERROR";

export const GET_POPULAR_POST_REQUEST = "GET_POPULAR_POST_REQUEST";
export const GET_POPULAR_POST_SUCCESS = "GET_POPULAR_POST_SUCCESS";
export const GET_POPULAR_POST_ERROR = "GET_RECENT_POST_ERROR";
