import {
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_ERROR,
  GET_SINGLE_BLOG_REQUEST,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_ERROR,
  GET_RECENT_POST_REQUEST,
  GET_RECENT_POST_SUCCESS,
  GET_RECENT_POST_ERROR,
  GET_POPULAR_POST_REQUEST,
  GET_POPULAR_POST_SUCCESS,
  GET_POPULAR_POST_ERROR,
  GET_HOME_PAGE_BLOGS_ERROR,
  GET_HOME_PAGE_BLOGS_SUCCESS,
  GET_HOME_PAGE_BLOGS_REQUEST,
} from "../actionTypes";

let initialstate = {
  homeLoading: false,
  loading: false,
  error: null,
  homePageBlogs: [],
  blogs: [],
  totalPages: 0,
  totalBlogs: 0,
  singleBlog: null,
  recentBlogs: null,
  popularBlogs: null,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case GET_HOME_PAGE_BLOGS_REQUEST:
      return {
        ...state,
        homeLoading: true,
      };
    case GET_HOME_PAGE_BLOGS_SUCCESS:
      return {
        ...state,
        homeLoading: false,
        homePageBlogs: action.payload.data,
      };
    case GET_HOME_PAGE_BLOGS_ERROR:
      return {
        ...state,
        homeLoading: false,
        error: action.payload,
      };

    case GET_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPages: action.payload.totalPages,
        totalBlogs: action.payload.totalRecords,
        blogs: action.payload.data,
      };
    case GET_BLOGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SINGLE_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBlog: action.payload,
      };
    case GET_SINGLE_BLOG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_RECENT_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECENT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        recentBlogs: action.payload,
      };
    case GET_RECENT_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_POPULAR_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_POPULAR_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        popularBlogs: action.payload,
      };
    case GET_POPULAR_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
