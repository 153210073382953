import classNames from "classnames";
import {} from "react";
import React, {
  FC,
  ReactElement,
  ChangeEvent,
  Fragment,
  KeyboardEvent,
} from "react";
import { IBaseInput } from "../../../models/base-input";

export interface BaseInputNumberProps extends IBaseInput {
  header?: string;
  slot?: ReactElement;
  footer?: string;
  isCode?: boolean;
}

export const BaseInputNumber: FC<BaseInputNumberProps> = ({
  value = "",
  name,
  error = false,
  disabled,
  onChange,
  required,
  header,
  slot,
  footer,
  isCode,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    onChange({
      value: target.value,
      name: target.name,
    });
  };

  const onKeyPressHandler = (e: KeyboardEvent<HTMLInputElement>): void => {
    const currentKey: string = e.key;
    const isNumberKeys: boolean = /^[0-9]+([.][0-9]+)?$/.test(currentKey);

    if (!isNumberKeys) {
      e.preventDefault();
    }
  };

  return (
    <div className='input'>
      {header && <div className='input__header'>{header}</div>}
      <input
        name={name}
        value={value}
        onChange={onChangeHandler}
        onKeyPress={onKeyPressHandler}
        disabled={disabled}
        className={classNames("input__field", {
          "input__field--code": isCode,
          "input__field--has-value": value,
          "input__field--error": error,
        })}
      />
      {slot && (
        <div className='input__slot'>
          <Fragment>{slot}</Fragment>
        </div>
      )}
      {footer && <div className='input__footer'>{footer}</div>}
    </div>
  );
};
