import {
  FILTER_CATEGORY_BY_GENDER,
  FILTER_SIZE_BY_CATEGORY,
  RESET_SIDEBAR,
  RESET_ALL_STATE,
  GET_SIDEBAR_REQUEST,
  GET_SIDEBAR_SUCCESS,
  GET_SIDEBAR_ERROR,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERROR,
  GET_FILTER_PRODUCTS_REQUEST,
  GET_FILTER_PRODUCTS_SUCCESS,
  GET_FILTER_PRODUCTS_ERROR,
  INITIAT_DEAL_REQUEST,
  INITIAT_DEAL_SUCCESS,
  INITIAT_DEAL_ERROR,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
} from "../actionTypes";
import _ from "lodash";
import { CATEGORY_SUBCATEGORY } from "../../global/config";

let initialstate = {
  loading: false,
  productLoading: false,
  error: null,
  sidebar: [],
  sidebarFilter: [
    {
      category_SubCategory: _.filter(CATEGORY_SUBCATEGORY, function (o) {
        return o.main_cat_id === 1; // default women categories [1-women , 2-man]
      }),
    },
  ],
  productsList: [],
  filterProductsList: [],
  total: 0,
  singleProductDetails: null,
  sizeSet: [],
  createDeal: null,
  premiumBrands: [],
  premiumBrandsIds: [],
  sneackersCategory: null,
  heelsCategory: null,
  allBrands: [],
  filterSections: null,
  tagSelections: null,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case GET_SIDEBAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SIDEBAR_SUCCESS:
      // is_premium
      const premiumBrands = _.filter(action.payload.data.brands, function (o) {
        return o.is_premium == 1;
      });
      // _.map(premiumBrands, (o) => console.log("B_ID=>", o.brand_id))
      const pre = [];
      _.map(premiumBrands, (o) => pre.push(o.brand_id));
      const otherBrands = _.filter(action.payload.data.brands, function (o) {
        return o.is_premium != 1;
      });
      const sortedPremiumBrands = _.orderBy(
        premiumBrands,
        [(brand) => brand.brand_name.toLowerCase()],
        ["asc"]
      );
      const sortedotherBrands = _.orderBy(
        otherBrands,
        [(brand) => brand.brand_name.toLowerCase()],
        ["asc"]
      );
      const findFootwareCategory = _.filter(
        action.payload.data.category_SubCategory,
        function (o) {
          return o.categorynames === "Обувь" && o.main_cat_id === 1;
        }
      );
      let findSneackersCat = null;
      let findHeelsCat = null;
      if (findFootwareCategory.length > 0) {
        const findSneackers = _.filter(
          findFootwareCategory[0].sub_categories,
          function (o) {
            return o.sub_categorynames === "Кеды и кроссовки";
          }
        );
        const findHeels = _.filter(
          findFootwareCategory[0].sub_categories,
          function (o) {
            return o.sub_categorynames === "Туфли";
          }
        );

        findSneackersCat = findSneackers[0];
        findHeelsCat = findHeels[0];
      }
      return {
        ...state,
        loading: false,
        premiumBrands: premiumBrands,
        premiumBrandsIds: pre,
        sneackersCategory: findSneackersCat,
        heelsCategory: findHeelsCat,
        allBrands: action.payload.data.brands,
        sidebar: action.payload.data, //action.payload,
        sidebarFilter: {
          ...action.payload.data,
          brands: [...sortedPremiumBrands, ...sortedotherBrands],
          size_sets: [],
          category_SubCategory: _.filter(CATEGORY_SUBCATEGORY, function (o) {
            return o.main_cat_id === action.payload.gender; // default women categories [1-women , 2-man]
          }),
        },
      };
    case GET_SIDEBAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_SIDEBAR:
      return {
        ...state,
        sizeSet: [],
        sidebarFilter: {
          ...state.sidebarFilter,
          size_sets: [],
        },
      };

    case RESET_ALL_STATE:
      return {
        ...initialstate,
        premiumBrands: state.premiumBrands,
      };

    case "RELOAD_SIDEBAR_CATEGORY":
      return {
        ...state,
        sidebarFilter: {
          ...state.sidebarFilter,
          category_SubCategory: _.filter(CATEGORY_SUBCATEGORY, function (o) {
            return o.main_cat_id === action.payload; // default women categories [1-women , 2-man]
          }),
        },
      };

    case "RESET_FILTER_SELECTION":
      return {
        ...state,
        filterSections: null,
        tagSelections: null,
      };

    case FILTER_CATEGORY_BY_GENDER:
      return {
        ...state,
        sidebarFilter: {
          ...state.sidebar,
          // ...state.sidebarFilter,
          size_sets: [],
          category_SubCategory: _.filter(CATEGORY_SUBCATEGORY, function (o) {
            return o.main_cat_id === action.payload;
          }),
        },
      };

    case FILTER_SIZE_BY_CATEGORY:
      // return {
      //     ...state,
      //     sidebarFilter: {
      //         ...state.sidebarFilter,
      //         size_sets: _.filter(state.sidebar.size_sets, function (o) {
      //             return o.id === action.payload
      //         })
      //     }
      // }

      if (action.payload.type === "add") {
        /** */
        const data = _.filter(state.sidebar.size_sets, function (o) {
          return o.id === action.payload.id;
        });
        state.sizeSet = [...state.sizeSet, ...data];
      } else {
        // const data = _.filter(state.sidebar.size_sets, function (o) {
        //   return o.id !== action.payload.id;
        // });
        const data = _.filter(state.sizeSet, function (o) {
          return o.id !== action.payload.id;
        });
        state.sizeSet = data;
      }

      return {
        ...state,
        sidebarFilter: {
          ...state.sidebarFilter,
          size_sets: state.sizeSet,
        },
      };

    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        productLoading: true,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productLoading: false,
        productsList: action.payload,
      };

    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        productLoading: false,
      };

    case GET_FILTER_PRODUCTS_REQUEST:
      return {
        ...state,
        productLoading: true,
      };

    case GET_FILTER_PRODUCTS_SUCCESS:
      /** Show newest uploaded propduct first */
      return {
        ...state,
        productLoading: false,
        productsList: action.payload, // not useful
        filterProductsList: action.payload.data,
        // filterProductsList: sortedProducts,
        total: action.payload.count,
        filterSections: action.payload.reservData.filterSelections,
        tagSelections: action.payload.reservData.tagSelections,
      };

    case GET_FILTER_PRODUCTS_ERROR:
      return {
        ...state,
        productLoading: false,
      };

    case GET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        productLoading: true,
      };

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productLoading: false,
        singleProductDetails: action.payload,
      };

    case GET_PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        productLoading: false,
      };

    case INITIAT_DEAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case INITIAT_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        createDeal: action.payload,
      };
    case INITIAT_DEAL_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
