import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { IBaseInput } from "../../../models/base-input";
import classNames from "classnames";

interface Props extends IBaseInput {
  header?: string;
  slot?: ReactElement;
  footer?: string;
  className?: string;
}

export const BaseInputPhone: FC<Props> = ({
  name,
  value = "",
  header,
  footer,
  error = false,
  disabled,
  onChange,
  slot,
  className,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    onChange({
      value: target.value,
      name: target.name,
    });
  };

  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={`input ${className}`}>
      {header && (
        <div
          className={classNames("input__header", {
            "input__header--disabled": disabled,
          })}
        >
          {header}
        </div>
      )}
      <div
        className={classNames("input__phone-wrapper", {
          "input__phone-wrapper--disabled": disabled,
        })}
      >
        <InputMask
          value={inputValue}
          mask='7 (999) 999-99-99'
          onChange={onChangeHandler}
          name={name}
          disabled={disabled}
          className={classNames("input__field input__field--phone", {
            "input__field--has-value": value,
            "input__field--error": error,
            "input__field--disabled": disabled,
          })}
        />
      </div>
      {slot && <div className='input__slot'>{slot}</div>}
      {footer && <div className='input__footer'>{footer}</div>}
    </div>
  );
};
