import React, { FC, ReactElement, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BasePopover } from "../base-popover/base-popover";
import { useHistory } from "react-router-dom";

import "./header-control.scss";
import { clearProfile } from "../../../store/actions";

interface Props {
  user?: any;
  dealsCount?: number;
}

export const HeaderControl: FC<Props> = ({
  user,
  dealsCount,
}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const goTo = (link: string) => {
    // @ts-ignore
    ref.current.click();
    history.push(link);
  };

  const logout = () => {
    localStorage.clear();
    dispatch(clearProfile());
    history.push("/");
  };

  const formatName = (fio: string) => {
    let result: string = "";
    const name = fio.split(" ");
    if (!!name[0]) result += name[0];
    if (!!name[1]) result += ` ${name[1].charAt(0)}. `;
    if (!!name[2]) result += `${name[2].charAt(0)}.`;
    return result;
  };

  const DropDown = () => {
    return (
      <div className='header-control__list'>
        <div className='header-control__info'>
          <div className='header-control__info-avatar'></div>
          <div className='header-control__info-box'>
            <div className='header-control__info-name'>
              {formatName(user.first_name)}
            </div>
            <div className='header-control__info-nick'>{user.nick_name}</div>
          </div>
        </div>

        <div
          onClick={() => goTo("/profile")}
          className='header-control__list-link'
        >
          Контактные данные
        </div>
        <div
          onClick={logout}
          className='header-control__list-link header-control__list-link--last'
        >
          Выйти
        </div>
      </div>
    );
  };

  return (
    <div className='header-control'>
      <Link
        to='/orders?current'
        className='header-control__item header-control__item--basket'
      >
        Сделки
        {!!dealsCount && (
          <div className='header-control__count'>{dealsCount}</div>
        )}
      </Link>
      <div className='header-control__item header-control__item--dropdown'>
        <BasePopover
          trigger={"click"}
          content={<DropDown />}
          placement='bottom'
        >
          <div ref={ref} className='header-control__trigger'>
            {formatName(user.first_name)}
          </div>
        </BasePopover>
      </div>
    </div>
  );
};
