import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_ALL_CITY_REQUEST,
  GET_ALL_CITY_SUCCESS,
  GET_ALL_CITY_ERROR,
} from "../actionTypes";

let initialstate = {
  loading: false,
  error: null,
  user: null,
  city: [],
  data: null,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALL_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        city: action.payload,
      };
    case GET_ALL_CITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
