import classNames from "classnames";
import React, { FC, Fragment, ReactElement, ChangeEvent } from "react";
import { ERegEx, regExList } from "../../../constants/regExList";
import { IBaseInput } from "../../../models/base-input";

interface Props extends IBaseInput {
  header?: string;
  slot?: ReactElement;
  footer?: string;
}

export const BaseInputEmail: FC<Props> = ({
  name,
  value = "",
  placeholder,
  header,
  slot,
  footer,
  error = false,
  disabled,
  onChange,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const localValue = target.value.replace(regExList[ERegEx.ONLY_SPACES], "");

    onChange({
      value: localValue,
      name: target.name,
    });
  };

  return (
    <div className="input">
      {header && <div className="input__header">{header}</div>}
      <input
        type="email"
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChangeHandler}
        disabled={disabled}
        className={classNames("input__field", {
          "input__field--has-value": value,
          "input__field--error": error,
        })}
      />
      {slot && (
        <div className="input__slot">
          <Fragment>{slot}</Fragment>
        </div>
      )}
      {footer && <div className="input__footer">{footer}</div>}
    </div>
  );
};
