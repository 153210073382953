import axios from "axios";
import {
  GET_PREMIUM_PRODUCT_REQUEST,
  GET_PREMIUM_PRODUCT_SUCCESS,
  GET_PREMIUM_PRODUCT_ERROR,
  GET_CLEAR_PRODUCTS,
} from "../actionTypes";

import { BASE_API_URL } from "../../global/ApiConfig";

export const clearProducts = () => {
  return (dispatch) => {
    dispatch({ type: GET_CLEAR_PRODUCTS });
  };
};

export const getPremiumProductList = (data, loadMore = false) => {
  return (dispatch) => {
    dispatch({ type: GET_PREMIUM_PRODUCT_REQUEST });
    axios
      .post(`${BASE_API_URL}GetProductsWithFilters`, data)
      .then((res) => {
        dispatch({
          type: GET_PREMIUM_PRODUCT_SUCCESS,
          // payload: res.data.data
          payload: {
            data: res.data.data,
            count: res.data.count,
            isLoadMore: loadMore,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PREMIUM_PRODUCT_ERROR,
          payload: err,
        });
      });
  };
};
