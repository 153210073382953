import React, { FC, ReactElement } from "react";
import AppDownload from "../../../components/AppDownload";
import MobilePreview from "../../../images/home/mobile-preview.png";

interface Props {
  title: string;
  description: string;
  className?: string;
}

export const QrBanner: FC<Props> = ({
  title,
  description,
  className,
}): ReactElement => {
  return (
    <section className={`poshapp-section section ${className}`}>
      <div className="container">
        <div className="posh-app">
          <div className="text-content">
            <h2 className="heading">{title}</h2>
            <p>{description}</p>
            <div className="barcode">
              <div className="image">
                <img src="/images/home/barcode.svg" alt="barcode" />
              </div>
              <p>
                Наведите камеру телефона на QR-код, чтобы скачать приложение
              </p>
            </div>
            <AppDownload />
            <div className="image app-image desktop-hide">
              <img src={MobilePreview} alt="mobile-app" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
