import React, { Component } from "react";
import { Dropdown, NavDropdown, Alert } from "react-bootstrap";
import { NavLink, withRouter, Link } from "react-router-dom";
import Logo from "./../../shared/images/icons/icon-logo.svg";
import "./Header.scss";
import { isUserLogin } from "../../global/GetToken";
import { connect } from "react-redux";
import { getSidebar, clearProfile } from "../../store/actions";
import _get from "lodash/get";
import mobLogo from "../../images/posh-mob-logo.png";
// import useBreakpoint from "../../global/useBreakpoint";

import { HeaderControl } from "./../../shared/components";

import classNames from "classnames";

import { modalOpen } from "../../store/actions";

// const UserProfileMenu = (props) => {
//   const logout = () => {
//     console.log("-");
//     localStorage.clear();
//     props.navigateTo();
//   };

//   console.log("props.deal ", props.deal);

//   return (
//     <HeaderControl
//       dealsCount={props.deal === [] ? 0 : 1}
//       user={props.profile}
//       logout={logout}
//     />
//   );
// };

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      show: true,
    };
  }

  // logout = () => {
  //   console.log("-");
  //   localStorage.clear();
  //   props.navigateTo();
  // };

  componentDidMount() {
    let gen = 1;
    if (this.props.location.pathname === "/man") {
      gen = 2;
    }
    const gender = gen;
    const params = {
      user_id: "",
      language_id: "2",
      is_change_language: "0",
    };
    this.props.dispatch(getSidebar(params, gender));
  }

  navigate = () => {
    this.props.history.push("/");
  };

  gotoLogin = () => {
    // this.props.history.push("/login");
    this.props.dispatch(clearProfile());
    this.props.history.push("/");
  };

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  // products
  render() {
    const classOne = this.state.collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const { show } = this.state;

    const isMainPage = this.props.history.location.pathname === "/";

    return (
      <>
        <Alert
          variant='light'
          show={show}
          onClose={() => this.setState({ show: false })}
          dismissible={false}
          className='app-alert-1'
        >
          <div className='app-dialog'>
            <img src={mobLogo} alt='mb-logo' />
            <div className='app-title'>
              <div>Установите мобильное приложение</div>
              <div>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
              </div>
            </div>
            <a
              className='btn-store ml-auto'
              href='https://redirect.appmetrica.yandex.com/serve/387967698493040946'
              // href='https://posh-market.onelink.me/J8mW/b90b0697'
            >
              Скачать
            </a>
          </div>
        </Alert>
        <div className='container'>
          <header
            className={classNames("navbar navbar-expand-lg", {
              "navbar--with-offset": !isMainPage,
            })}
          >
            <div
              className={classNames("navbar-border pb-lg-3", {
                // "button-group__item--full-width": isMainPage,
              })}
            >
              <Link to='/' className='navbar-brand'>
                <img src={Logo} alt='posh-app-logo' />
              </Link>
              <button
                onClick={() => this.toggleNavbar()}
                className='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navbarNav'
                aria-controls='navbarNav'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
              {/* <div className="collapse navbar-collapse" id="navbarNav"> */}
              <div
                // className={`${classOne} navbar-nav--with-border`}
                className={classNames(classOne, {
                  "navbar-nav--with-border": !isMainPage,
                })}
                id='navbarNav'
              >
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <NavLink
                      to={{
                        pathname: "/women",
                        gender: "1",
                      }}
                      activeClassName='active'
                    >
                      Женское
                      {/* Women */}
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to={{
                        pathname: "/men",
                        gender: "2",
                      }}
                      activeClassName='active'
                    >
                      Мужское
                      {/* Man */}
                    </NavLink>
                  </li>
                  <li>
                    <NavDropdown
                      className='brand-drop-header'
                      title='Бренды' // Designers
                      id='basic-nav-dropdown'
                    >
                      {/* <h5>Editor's pick</h5> */}
                      <h5>Популярные бренды</h5>
                      <ul className='linklist'>
                        {_get(this, "props.premiumBrands", []).map(
                          (b_item, i) => (
                            <li key={i}>
                              <NavDropdown.Item
                                href={`/women/brands/${_get(
                                  b_item,
                                  "brand_name",
                                  ""
                                )}/${_get(b_item, "brand_id", "")}`}
                              >
                                {_get(b_item, "brand_name", "")}
                              </NavDropdown.Item>
                            </li>
                          )
                        )}
                        <li>
                          <NavDropdown.Item href='/brands'>
                            {/* All brands */}
                            Показать все
                          </NavDropdown.Item>
                        </li>
                      </ul>
                    </NavDropdown>
                  </li>
                  {/* <li className="nav-item dropdown">
                <NavLink
                  to="javascript:void(0)"
                  activeClassName="active2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  Designers
                </NavLink>
                <div className="sub-menu dropdown-menu">
                  <div className="container">
                    <h5>Editor's pick</h5>
                    {loading && (
                      <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw" />
                    )}
                    <ul>
                      {_get(this, "props.premiumBrands", []).map(
                        (b_item, i) => (
                          <li key={i}>
                            <Link
                              to={`/women/brands/${_get(
                                b_item,
                                "brand_name",
                                ""
                              )}/${_get(b_item, "brand_id", "")}`}
                            >
                              {_get(b_item, "brand_name", "")}
                            </Link>
                          </li>
                        )
                      )}
                      <li>
                        <Link to="/brands">
                          <u>All brands</u>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li> */}
                  <li className='nav-item'>
                    <NavLink to='/blog' activeClassName='active'>
                      Блог
                      {/* Blog */}
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink to='/about' activeClassName='active'>
                      О нас
                      {/* About us */}
                    </NavLink>
                  </li>
                </ul>
                <div className='user-navbar ml-auto'>
                  {!!this.props.profile &&
                  !!this.props.profile.username &&
                  this.props.deal ? (
                    <HeaderControl
                      dealsCount={
                        this.props.activeDeals === []
                          ? 0
                          : this.props.activeDeals.length
                      }
                      user={this.props.profile}
                    />
                  ) : (
                    <div
                      className='header-login'
                      onClick={() =>
                        this.props.dispatch(modalOpen("auth-phone"))
                      }
                    >
                      Логин/Регистрация
                    </div>
                  )}

                  {/* {!this.props.profile && !this.props.profile.username && (
                    <div>Логин</div>
                  )} */}

                  {/* {!isUserLogin() && (
                    <a href='/login' className='user-navbar__link'>
                      Логин/Регистрация
                    </a>
                  )} */}

                  {/* ) : null} */}
                  {/* <ul className="navbar-nav">
                                        <li className="nav-item"><NavLink to="/login" className="nav-link">Login</NavLink></li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </header>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ product, profile, deal }) => {
  return {
    loading: product.loading,
    premiumBrands: product.premiumBrands,
    profile: profile.data,
    deal: deal.allDeals,
    activeDeals: deal.activeDeals,
  };
};

export default connect(mapStateToProps)(withRouter(Header));
