import React, { FC, ReactElement, useEffect, useRef } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

import "./base-popover.scss";
import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";

import {
  AutoPlacement,
  BasePlacement,
  VariationPlacement,
} from "@popperjs/core/lib/enums.d";

interface Props {
  title?: string;
  content: ReactElement;
  placement?: AutoPlacement | BasePlacement | VariationPlacement;
  trigger?: OverlayTriggerType;
  className?: string;
}

export const BasePopover: FC<Props> = ({
  content,
  title,
  children,
  trigger = "hover",
  placement = "auto",
  className,
}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const popover = (
    <Popover id='popoverId'>
      {title && <Popover.Title>{title}</Popover.Title>}
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );

  useEffect(() => {
    return () => {
      if (ref !== null) {
        // ref.current.click();
      }
    };
  }, []);

  return (
    <OverlayTrigger
      trigger={[trigger, trigger]} // it's huck
      overlay={popover}
      placement={placement}
      rootClose
    >
      <div ref={ref} className={`overlay-wrapper ${className}`}>
        {children}
      </div>
    </OverlayTrigger>
  );
};
