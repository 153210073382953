export const BASE_API_URL =
  "https://napi.posh-market.ru/App/LiveAPI/API/HiShoppersService.php?Service=";
export const NEW_API_URL = " https://napi.posh-market.ru/App/Laravel/public/api";

// export const NEW_API_URL = "https://nback.posh-market.ru/api";

if (process.env.NODE_ENV === "development") {
  // some logic
}

// export const X_API_KEY =
//   "dsfugfew9fg9ew8f7g9ew87gfew87fgew98f7gew87fgwe98fgiwegkywkad0wqs2bpi";

export const X_API_KEY_PROD =
  "YHJfdhjk2ejkhrwebkjfbhk2jbnskdjhfsndfkjshdgknbcsv171748";

export const PAGINATE_COUNT = 40;
