import React, { FC, ReactElement, Fragment, ChangeEvent } from "react";
import "../../../scss/modules/select.scss";

// @ts-ignore
import Select from "react-select";

export interface SelectItem {
  value: string;
  label: string;
}

interface Props {
  defaultValue: SelectItem;
  items: SelectItem[];
  header?: string;
  placeholder?: string;
  loadingMessage?: string;
  noOptionsMessage?: string;
  slot?: ReactElement;
  footer?: string;
  className?: string;
  onChange?: (payload: any) => void;
  onInputChange?: (payload: any) => void;
  onScrollBottom?: () => void;
}

export const BaseSelect: FC<Props> = ({
  defaultValue,
  items,
  header,
  placeholder,
  loadingMessage,
  slot,
  footer,
  className,
  onChange,
  onInputChange,
  onScrollBottom,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange &&
      onChange({
        ...e,
      });
  };

  return (
    <div className={`input ${className}`}>
      {header && <div className='input__header'>{header}</div>}
      <Select
        // menuIsOpen={true}
        cacheOptions
        isClearable
        focus={() => console.log("---")}
        defaultValue={defaultValue}
        isSearchable
        LoadingMessage={() => loadingMessage}
        placeholder={placeholder}
        options={items}
        classNamePrefix='mySelect'
        noOptionsMessage={() => null}
        onChange={onChangeHandler}
        components={{
          // SingleValue: customSingleValue,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null,
        }}
        className='base-select'
        onInputChange={onInputChange}
        onMenuScrollToBottom={onScrollBottom}
        // styles={customStyles}
      />
      {slot && (
        <div className='input__slot'>
          <Fragment>{slot}</Fragment>
        </div>
      )}
      {footer && <div className='input__footer'>{footer}</div>}
    </div>
  );
};
