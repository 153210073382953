import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import "./order-info.scss";

export interface IOrderInfoMeta {
  firstName?: string;
  image: string;
  category: string;
  name: string;
  size: string;
}

export interface IOrderInfoSection {
  label: string;
  description?: string;
  price?: number;
}

export interface IOrderInfoList {
  title?: string;
  section: IOrderInfoSection[];
  hasTotal?: boolean;
}

export interface IOrderInfo {
  deal_id?: number;
  meta?: IOrderInfoMeta;
  list: IOrderInfoList[];
  hasTotal?: boolean;
}

interface Props {
  title: string;
  info: IOrderInfo;
  inLine?: boolean;
  columnReverse?: boolean;
  className?: string;
}

export const OrderInfo: FC<Props> = ({
  title,
  info,
  inLine,
  columnReverse,
  className,
}): ReactElement => {
  // console.log("перерендер");

  const calcSectionTotalPrice = (item: any): string => {
    const summ: number = item.reduce(
      (total: any, amount: IOrderInfoSection) => {
        return total + amount.price;
      },
      0
    );

    return summ.toLocaleString();
  };

  const calcTotalPrice = (): string => {
    let summ: number = 0;
    // TODO: rebuild by reducer
    info.list.forEach((list: any) => {
      list.section.forEach((item: any) => {
        summ += item.price;
      });
    });

    return summ.toLocaleString();
  };

  return (
    <div className={classNames(className, "order-info")}>
      <div className='order-info__title'>{title}</div>
      {info.meta && (
        <div className='order-info__meta'>
          <div
            className='order-info__meta-image'
            style={{ backgroundImage: `url(${info.meta.image})` }}
          />
          <div className='order-info__meta-col'>
            <div className='order-info__meta-text'>{info.meta.category}</div>
            <div className='order-info__meta-text'>{info.meta.name}</div>
            <div className='order-info__meta-size'>
              Размер: {info.meta.size}
            </div>
          </div>
        </div>
      )}
      {info.list.map((list: IOrderInfoList, key: number) => (
        <div
          className={classNames("order-info__section", {
            "order-info__section--with-delimiter": info.meta,
          })}
          key={key}
        >
          {list.title && (
            <div className='order-info__section-title'>{list.title}</div>
          )}
          {list.section.map((item: IOrderInfoSection, _key: number) => (
            <div
              key={_key}
              className={classNames("order-info__row", {
                "order-info__row--inline": inLine,
                "order-info__row--auto-height": info.meta,
              })}
            >
              <div
                className={classNames(
                  "order-info__col order-info__col--fluid",
                  {
                    "order-info__col--reverse": columnReverse,
                  }
                )}
              >
                <div
                  className={classNames("order-info__text", {
                    "order-info__text--bold": inLine,
                  })}
                >
                  {item.label}
                </div>
                {item.description && (
                  <div className='order-info__text order-info__text--small'>
                    {item.description}
                  </div>
                )}
              </div>
              {item.price && (
                <div className='order-info__col'>
                  <div className='order-info__text order-info__text--price order-info__text--bold'>
                    {item.price.toLocaleString()}
                  </div>
                </div>
              )}
            </div>
          ))}
          {list.hasTotal && (
            <div className='order-info__row order-info__row--last'>
              <div className='order-info__col order-info__col--fluid'>
                <div className='order-info__text order-info__text--bold'>
                  Итого
                </div>
              </div>
              <div className='order-info__col'>
                <div className='order-info__text order-info__text--price order-info__text--bold'>
                  {calcSectionTotalPrice(list.section)}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      {info.hasTotal && (
        <div className='order-info__row order-info__row--last'>
          <div className='order-info__col order-info__col--fluid'>
            <div className='order-info__text order-info__text--bold'>
              Общая стоимость
            </div>
          </div>
          <div className='order-info__col'>
            <div className='order-info__text order-info__text--price order-info__text--bold'>
              {calcTotalPrice()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
