import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import "./base-alert.scss";

export enum EBaseAlertType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}
interface Props {
  type?: EBaseAlertType;
  title: string;
  description: string;
  className?: string;
  onClose?: () => void;
}

export const BaseAlert: FC<Props> = ({
  type = EBaseAlertType.WARNING,
  title,
  description,
  className,
  onClose,
}): ReactElement => {
  return (
    <div className={classNames(className, "base-alert", `base-alert--${type}`)}>
      {title && <h5 className="base-alert__title">{title}</h5>}
      {description && <p className="base-alert__description">{description}</p>}
      <span className="base-alert__btn-close" onClick={onClose} />
    </div>
  );
};
