import classNames from "classnames";
import React, { FC, ReactElement, Fragment, ChangeEvent } from "react";
import { IBaseInput } from "../../../models/base-input";

interface Props extends IBaseInput {
  header?: string;
  slot?: ReactElement;
  footer?: string;
  className?: string;
  isPlaceholderNoOffset?: boolean;
}

export const BaseTextArea: FC<Props> = ({
  name,
  value = "",
  placeholder,
  isPlaceholderNoOffset,
  header,
  slot,
  footer,
  error = false,
  disabled,
  className,
  onChange,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const target = e.target as HTMLTextAreaElement;

    onChange({
      value: target.value,
      name: target.name,
    });
  };

  return (
    <div className={`input ${className}`}>
      {header && (
        <div
          className={classNames("input__header", {
            "input__header--no-left-offset": isPlaceholderNoOffset,
          })}
        >
          {header}
        </div>
      )}
      <textarea
        // type='text'
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChangeHandler}
        disabled={disabled}
        className={classNames("input__field input__field--textarea", {
          "input__field--has-value": value,
          "input__field--error": error,
          "input__field--disabled": disabled,
        })}
      />
      {slot && (
        <div className='input__slot'>
          <Fragment>{slot}</Fragment>
        </div>
      )}
      {footer && <div className='input__footer'>{footer}</div>}
    </div>
  );
};
