import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { BaseTabs } from "..";
import { ITab, ITabComplex } from "../../components/base-tabs/base-tabs";

import classNames from "classnames";
import "./base-drawer.scss";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
}

interface ISteps {
  image: string;
  title: string;
  description: string;
  note?: string;
  withoutBadge?: boolean;
  blue?: boolean;
}

export const BaseDrawer: FC<Props> = ({
  isOpen = false,
  onClose,
}): ReactElement => {
  const initialControls: ITabComplex[] = [
    {
      key: "main",
      items: [
        {
          label: "Запросить наличие",
          active: true,
        },
        {
          label: "Купить",
          active: false,
        },
      ],
    },
    {
      key: "second",
      items: [
        {
          label: "Покупаю",
          active: true,
        },
        {
          label: "Продаю",
          active: false,
        },
      ],
    },
  ];

  // const [controls, setControls] = useState<ITabComplex[]>(initialControls);

  // const [stepsAvailabilityBuy] = useState<ISteps[]>([
  //   {
  //     image: "icon-step-1",
  //     title: "Запросите наличие товара",
  //     description: "Дождитесь подтверждения наличия товара продавцом",
  //   },
  //   {
  //     image: "icon-step-2",
  //     title: "Продавец подтвердил наличие",
  //     description:
  //       "После подтверждения наличия необходимо оплатить товар в течение 72 часов",
  //     note: "Иначе сделка будет автоматически отменена",
  //   },
  //   {
  //     image: "icon-step-3",
  //     title: "Оплатите товар",
  //     description:
  //       "Мы спишем деньги с карты и будем хранить их на специальном счетедо окончания сделки",
  //   },
  //   {
  //     image: "icon-step-4",
  //     title: "Доставка",
  //     description:
  //       "После оплаты, продавец отправит ваш товар через курьерскую службу СДЭК",
  //   },
  //   {
  //     image: "icon-step-5",
  //     title: "Проверка подлинности",
  //     description:
  //       "Если вы заказали экспертизу или товар стоит дороже 20 000 ₽ — товар сначала направится в офис POSH MARKET для прохождения проверки подлинности",
  //   },
  //   {
  //     image: "icon-step-6",
  //     title: "Дождитесь доставки",
  //     description:
  //       "Проверьте товар при курьерена соответствие описанию. Вы можете вернуть посылку курьеру, толькоесли товар не соответствует описаниюв объявлении",
  //   },
  //   {
  //     image: "icon-step-7",
  //     title: "Оцените продавца",
  //     description: "Поставьте оценку продавцу за сделку",
  //     blue: true,
  //   },
  // ]);
  // const [stepsAvailabilitySell] = useState<ISteps[]>([
  //   {
  //     image: "icon-step-1",
  //     title: "Подтвердите наличие товара",
  //     description:
  //       "Получив запрос на сделку подтвердите наличие товара или отмените сделку",
  //   },
  //   {
  //     image: "icon-step-2",
  //     title: "Ожидайте оплаты покупателем",
  //     description:
  //       "Покупатель должен оплатить товарв течение 72 часов, Иначе сделка будет автоматически отменена",
  //     note: "Деньги будут храниться на специальном счету до окончания сделки",
  //   },
  //   {
  //     image: "icon-step-3",
  //     title: "Оформите заявку на доставку",
  //     description:
  //       "Оформите заявку на доставку через курьерскую службу СДЭК. Процесс автоматизирован в приложении",
  //   },
  //   {
  //     image: "icon-step-4",
  //     title: "Доставка",
  //     description: "Передайте товар в службу поддержкии ожидайте доставки",
  //   },
  //   {
  //     image: "icon-step-5",
  //     title: "Проверка подлинности",
  //     description:
  //       "Если покупатель заказал экспертизу или товар стоит дороже 20 000 ₽ — товар сначала направится в офис POSH MARKET для прохождения проверки подлинности",
  //   },
  //   {
  //     image: "icon-step-6",
  //     title: "Дождитесь доставки покупателю",
  //     description:
  //       "Проверьте товар при курьерена соответствие описанию. Вы можете вернуть посылку курьеру, толькоесли товар не соответствует описаниюв объявлении",
  //   },
  //   {
  //     image: "icon-step-7",
  //     title: "Оцените покупателя",
  //     description:
  //       "Поставьте рейтинг покупателю. Сделка будет закрыта. Деньги за сделку будут зачислены в течение 1-2 рабочих дней",
  //     blue: true,
  //   },
  // ]);
  // const [stepsBuy] = useState<ISteps[]>([
  //   {
  //     image: "icon-step-1",
  //     title: "Покупка без подтверждения наличия",
  //     description:
  //       "При нажатии на «Купить» вы оплачиваете товар без подтверждения наличия от продавца",
  //     note: "Во время сделки мы замораживаем ваши деньги в банке. Если товар окажетсяне в наличии, то деньги автоматически вернуться к вам на счет",
  //     withoutBadge: true,
  //   },
  // ]);

  const [steps] = useState<ISteps[]>([
    {
      image: "icon-step-1",
      title: "Запросите наличие товара",
      description: "Дождитесь подтверждения наличия товара продавцом",
    },
    {
      image: "icon-step-2",
      title: "Продавец подтвердил наличие",
      description:
        "После подтверждения наличия необходимо оплатить товар в течение 72 часов",
      note: "Иначе сделка будет автоматически отменена",
    },
    {
      image: "icon-step-3",
      title: "Оплатите товар",
      description:
        "Деньги будут списаны с карты и храниться на специальном счету до окончания сделки",
    },
    {
      image: "icon-step-4",
      title: "Доставка",
      description:
        "Продавец отправляет товар через курьерскую службу СДЭК. Процесс автоматизирован в приложении",
    },
    {
      image: "icon-step-5",
      title: "Проверка подлинности",
      description:
        "Если товар стоит дороже 20000 руб. он сначала доставляется в офис POSH MARKET для прохождения проверки подлинности",
    },
    {
      image: "icon-step-6",
      title: "Дождитесь доставки",
      description:
        "Проверьте товар на соответствие описанию. Вы можете вернуть товар курьеру, только если товар не соответсвует описанию в объявлении",
    },
    {
      image: "icon-step-7",
      title: "Оцените сделку",
      description: "Сделка будет закрыта. Деньги будут отправлены продавцу",
      blue: true,
    },
  ]);

  // const initControls = useCallback(() => {
  //   if (!isOpen) {
  //     setControls(initialControls);
  //   }
  // }, [isOpen, initialControls]);

  // useEffect(() => {
  // initControls();
  // console.log(isOpen);
  // initControls();
  // if (!isOpen) {
  // setControls(initialControls);
  // } else {
  //   return;
  // }
  // }, [initControls]);
  // }, [isOpen, initialControls]);

  // const onChangeHandlerTabsMain = (el: ITab, key: string): void => {
  //   setControls(
  //     controls.map((control: ITabComplex) => {
  //       if (control.key === key) {
  //         return {
  //           ...control,
  //           items: control.items.map((item: ITab) => {
  //             return {
  //               ...item,
  //               active: el.label === item.label,
  //             };
  //           }),
  //         };
  //       } else {
  //         return control;
  //       }
  //     })
  //   );
  // };

  // const TabMainFirst = () => {
  //   return (
  //     <BaseTabs
  //       compact
  //       items={controls[1].items}
  //       tabs={[<TabSecondFirst />, <TabSecondSecond />]}
  //       onChange={(e) => onChangeHandlerTabsMain(e, "second")}
  //       headerClass={"base-drawer__tabs-control"}
  //       wrapperClass={"base-drawer__tabs-wrapper"}
  //     />
  //   );
  // };

  // const TabMainSecond = () => {
  //   return StepsList(stepsBuy);
  // };

  const TabSecondFirst = () => {
    return StepsList(steps);
  };

  // const TabSecondSecond = () => {
  //   return StepsList(stepsAvailabilitySell);
  // };

  const StepsList = (items: ISteps[]) => {
    return (
      <>
        {items.map((step: ISteps, index: number) => (
          <div key={index} className='base-drawer__step'>
            <div
              className={`base-drawer__step-image base-drawer__step-image--${
                index + 1
              }`}
            />
            <div
              className={classNames("base-drawer__step-box", {
                "base-drawer__step-box--offset-top": step.withoutBadge,
              })}
            >
              {!step.withoutBadge && (
                <span
                  className={classNames("base-drawer__step-badge", {
                    "base-drawer__step-badge--blue": step.blue,
                  })}
                >
                  Шаг {index + 1} из {items.length}
                </span>
              )}
              <div className='base-drawer__step-title'>{step.title}</div>
              <div className='base-drawer__step-description'>
                {step.description}
              </div>
              {step.note && (
                <div className='base-drawer__step-note'>{step.note}</div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      className={classNames("base-drawer", {
        "base-drawer--open": isOpen,
      })}
    >
      <div className='base-drawer__title'>Безопасная сделка с POSH MARKET</div>
      <div className='base-drawer__btn-close' onClick={onClose} />
      <div className='base-drawer__banner' />

      {/* <BaseTabs
        fluid
        items={controls[0].items}
        tabs={[<TabMainFirst />, <TabMainSecond />]}
        onChange={(e) => onChangeHandlerTabsMain(e, "main")}
        headerClass={"base-drawer__tabs-control"}
      /> */}

      <TabSecondFirst />
    </div>
  );
};
