import React, { FC, ReactElement, ChangeEvent } from "react";
import "./base-switch.scss";

interface Props {
  id?: string;
  value?: boolean;
  onChange: (item: boolean) => void;
}

export const BaseSwitch: FC<Props> = ({
  value = false,
  id,
  onChange,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    // e.preventDefault();
    onChange((e.target as HTMLInputElement).checked);
  };

  return (
    <label className="base-switch" htmlFor={id}>
      <input
        id={id}
        checked={value}
        type="checkbox"
        onChange={onChangeHandler}
        className="base-switch__input"
      />
      <span className="base-switch__slider" />
    </label>
  );
};
