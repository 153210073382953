import React, { FC, ReactElement, useState } from "react";
import classNames from "classnames";

import "./base-rating.scss";

interface Props {
  className?: string;
  selectRating: (index: number) => void;
}

export const BaseRating: FC<Props> = ({
  className,
  selectRating,
}): ReactElement => {
  const [rating, setRating] = useState<number>(-1);

  const _selectRating = (index: number) => {
    setRating(index);
    selectRating(index);
  };

  return (
    <div className={classNames(className, "base-rating")}>
      {Array(5)
        .fill(0)
        .map((_: number, index: number) => (
          <div
            key={index}
            className={classNames("base-rating__icon", {
              "base-rating__icon--active": rating >= index,
            })}
            onClick={() => _selectRating(index)}
          />
        ))}
    </div>
  );
};
