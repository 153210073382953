import axios from "axios";
import { BASE_API_URL } from "../global/ApiConfig";

export const getNickname = async (payload: {
  nickname: any;
  build: any;
  version: any;
}): Promise<any> => {
  const { data } = await axios.post(
    // `https://nback.posh-market.ru/api/user/check-nickname?nickname=${payload.nickname}`,
    `${BASE_API_URL}CheckUsernameExists`,
    payload
  );
  // console.log(data);
  return data;
};
